import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceIdentityService } from 'src/app/shared/ATTT/service/auth-service-identity.service';
@Component({
  selector: 'app-verificar-mail',
  templateUrl: './verificar-mail.component.html',
  styleUrls: ['./verificar-mail.component.scss']
})
export class VerificarMailComponent implements OnInit {

  public token:string ='';
  public email:string ='';
  constructor(private rutaActiva: ActivatedRoute,private ruta:Router,private fb: FormBuilder, public authService:AuthServiceIdentityService) { 
    
    this.token= rutaActiva.snapshot.params.id;
    this.email= rutaActiva.snapshot.params.email;
    
    if (this.token =='') {
      ruta.navigate(['/auth/login']);
    }else{
      authService.ConfirmarEmail(this.token,this.email)
    }
  }

  ngOnInit(): void {
  }

}
