import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';
import * as CryptoJS from 'crypto-js';  
@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(public authService: AuthService,
    public router: Router) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    let activo = localStorage.getItem('user');
    let encrip = '';
    if(activo!=null){
      activo.replace('_','/');
      activo.replace('~','`/`');
      encrip = CryptoJS.AES.decrypt(activo.trim(), 'Panama2019$').toString(CryptoJS.enc.Utf8); 
      let user = JSON.parse(encrip);
      return true
    }else{
      this.router.navigate(['/auth/login']);
      return true
    }
    
  }
}
