import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { ConfigServiceService } from '../config/config-service.service';
import { LoginResponse, ResponseGeneral } from '../model/LoginRequest';
import { Router } from '@angular/router';
import { TallerServiceService } from './taller-service.service';
import { TallerResponsable } from '../model/RequestTallerR';

declare var require
const Swal = require('sweetalert2');
@Injectable({
  providedIn: 'root'
})
export class AuthServiceIdentityService {
  public showLoader: boolean = false;
  public showReset: boolean = false;
  public ReturnDashboard:boolean =false;
  public html:string ="";
  constructor(private _http:HttpClient,
    private _config:ConfigServiceService,
    public ngZone: NgZone,
    public NavCtrl:Router,
    public ServiceTaller:TallerServiceService
    ) {
    }

    // ValidarTallerActivo(){
    //   this.TallerService.GetResponsableTaller('username',this.info.)
  
    //  }
  // http://localhost:4200/auth/verificar/CfDJ8LIypqMemblJl8wm7cCc9sbsIfEwmJ9zVLL+_bZnUxtLQPohsdjG_gWqeSNLFYHrg57xLkfase6FlEYFITsHz+w2H9Nlos+_pkbx_DLwhxJlgOwNCSPvfs0iOnX3jrXYJoZrvOnZW2wXzcDIEAVM9I8j9xHGWXD4n+YZpfr1L5YENZrR7OxEEsOvIAopgwFXK2NTbZzbUdu4b_Q0skaXaAsHQ4j2CLoJIDYZTj5skzql2HrZA25pZQjOmYnzDtptig==/tonyjoe1110@hotmail.com
    AuthUser(usuario:any){
      this.showLoader = true;
    return this._http.post(`${this._config.URL_SERVICE_AUTH}/Auth/Login`,usuario)
                        .subscribe({
                          next:(res:LoginResponse)=>{
                            this.ngZone.run(()=>{
                              if(res.exito){
                                this._config.setUserLocal(res);
                                this.ReturnDashboard=true;
                              }else{
                                this.success('error','Intento Fallio',res.message);
                                this.showLoader=false;
                              }
                            });
                          },
                          error:(err)=>{
                            this.success('error','Intento Fallio','Ingrese Nuevamente');
                            this.showLoader = false;
                             this.NavCtrl.navigate(['/auth/login']);
                          },
                          complete:()=>{
                            this.showLoader=false;
                            if(this._config.IsTaller == true){
                              this.ValidarTallerActivo();
                            }else{
                              this.NavCtrl.navigate(['/Panel-control']);
                            }
                          }
                        });
   }

     ValidarTallerActivo(){
      this.ServiceTaller.GetOneResponsable('myusername',this._config.info.email).subscribe({
        next:(res:any)=>{
          
          this._config.TallerUserActivo = res;
          if(res==null){
            this.salirNavegador();
            this.success('warning','Ecarcheck','EL usuario con correo electrónico: '+this._config.info.email+' no tiene puesto de trabajo asignado.');
            return;
          }
          this._config.TallerUserActivo = res;
        
          
          
        },error:(error)=>{
          this.success('warning','Ecarcheck','Intente nuevamente, O verifique que tenga acceso a la red.');
          location.reload();
          localStorage.clear();
          this.NavCtrl.navigate(['/'])
          
        },
        complete:()=>{
          if (this._config.TallerUserActivo!=null) {
            this.ServiceTaller.GetonetallerActivo(this._config.TallerUserActivo.tallerId).subscribe({
              next:(res:any)=> {
                if(res.length==0){
                  this.success('warning','Ecarcheck','EL usuario con correo electrónico: '+this._config.info.email+' no tiene puesto de trabajo asignado.');
                  this.success('warning','Ecarcheck','No tiene Taller Asignado.');
                  return;
                }
                this._config.TallerActivo = res; 
              },
              complete:()=> {
                  this.ServiceTaller.ValidarEstacionTrabajo(this._config.TallerUserActivo.tallerId).subscribe({
                    next:(res:any)=>{
                      if(res.length==0){
                        this.salirNavegador();
                        this.success('warning','Ecarcheck','El Taller no mantiene puestos de trabajos asignados');
                        return;
                      }
                      this._config.PuestoTrabajoActivo = res[0];
                      
                    },
                    complete:()=> {
                      this.ServiceTaller.ValidarCuponesTaller(this._config.TallerUserActivo.tallerId).subscribe({
                        next:(res:any)=>{
                          
                          if(res.length>0){
                             for (let index = 0; index < res.length; index++) {
                              if(res[index].tipoCupo == '1'){
                                this._config.CuposTaller=res[index];
                              }

                              if(res[index].tipoCupo == '2'){
                                this._config.CuposTallerComercial=res[index];
                              }

                             }
                           
                          }else{
                            this._config.InitCupos();
                          }
                        },
                        complete:()=>{
                          if(this._config.PuestoTrabajoActivo.latitud == 0 && this._config.PuestoTrabajoActivo.longitud == 0){
                            this.html="<p style='font-size: 15px;padding: 15px;text-align: justify;'>Su Ubicación de Trabajo <strong>no esta definida</strong>, debe permitir la Geocalización en su navegador para poder avanzar en E-CAR-CHECK, una vez el GPS del navegador se habilita se tomara como puesto de trabajo permanente, si no se encuentra en el puesto de trabajo no habilite el GPS, porque no se va poder hacer el cambio del puesto de trabajo, solo mediente solicitud. </p><p class='text-danger' style='font-weight: bold;text-align: justify;padding: 15px;'>Debe Habilitar la Geocalización del Navegador para poder avanzar en E-CAR-CHECK</p>";
                            this.getLocation();
                          }else{
                            this.html="<p class='text-danger' style='font-weight: bold;text-align: justify;padding: 15px;'>Debe Habilitar la Geocalización del Navegador para poder avanzar en E-CAR-CHECK</p>";
                            this.getLocationLocal();
                          }
                            this._config.CargadoCompleto = true;
                            if (this.ReturnDashboard) {
                              this.NavCtrl.navigate(['/Panel-control']);
                            }
                            this._config.UsuarioActivo = true;
                              // this.success('success','Éxito',res.message);
                            this.showLoader=false;
                            this.ReturnDashboard=false;
                        },
                      })
                    },
                  })
              }
            })
          }else{
            this.salirNavegador();
            return;
          }
        }
      })
     }
  getLocation() {
       this._config.getPosition().then(pos => { 
          this._config.latitude = pos.lat;
          this._config.longitude = pos.lng;
          this._config.PuestoTrabajoActivo.latitud = this._config.latitude;
          this._config.PuestoTrabajoActivo.longitud = this._config.longitude;
          // llamar servicios log para guardar que se registro 
          this.ServiceTaller.SaveUbicacionPut(this._config.PuestoTrabajoActivo);
       }).catch((e: any) => {
        if(e.code ==1){
          this.ShowAlertGeocalizacion();
        }
        
      })
    }


    getLocationLocal() {
      this._config.getPosition().then(pos => {
          this._config.latitude = pos.lat;
          this._config.longitude = pos.lng;
      }).catch((e:any)=>{
        if(e.code ==1){
          this.ShowAlertGeocalizacion();
        }
        
      })
    }

    ShowAlertGeocalizacion(){
      this._config.latitude=0;
      this._config.longitude=0;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      
      swalWithBootstrapButtons.fire({
        title: 'Geocalización del Taller',
        html: this.html,
        showCancelButton: true,
        confirmButtonText: 'Si, Habilitar',
        cancelButtonText: 'No, Salir Ahora!',
        reverseButtons: true,
        imageUrl: 'https://cdn3.iconfinder.com/data/icons/web-hosting-2-1/128/104-512.png',
        imageWidth: 123,
        imageHeight: 123,
        imageAlt: 'Custom image',
      }).then((result) => {
        if (result.isConfirmed) {
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          this._config.limpiarLocal();
          this._config.UsuarioActivo = false;
          this.NavCtrl.navigate(['/auth/login']);
        }
      })
    }


   RecuperarPassword(usuario:any){
    this.showReset = true;
    return this._http.post(`${this._config.URL_SERVICE_AUTH}/Auth/Recuperar`,usuario)
                        .subscribe((res:ResponseGeneral)=>{
                          this.ngZone.run(()=>{

                            if(res.codeError ==200){
                              this.NavCtrl.navigate(['/auth/login']);
                              this.success('success','Éxito',res.message);
                              this.showReset = false;
                            }else{
                              this.success('error','Intento Fallio',res.message);
                              this.showReset = false;
                               this.NavCtrl.navigate(['/auth/login']);
                            }
                          });
      
      });
 }

 CambiarContrasena(usuario){
    return this._http.post(`${this._config.URL_SERVICE_AUTH}/Auth/ChangePassword`,usuario)
                    .subscribe((res:ResponseGeneral)=>{
                      this.ngZone.run(()=>{
                        if(res.codeError ==200){
                          this.NavCtrl.navigate(['/auth/login']);
                          this.success('success','Éxito',res.message);
                          this.showReset = false;
                        }else{
                          this.success('error','Intento Fallio',res.message);
                          this.showReset = false;
                           this.NavCtrl.navigate(['/auth/login']);
                        }
                      });

                  });
 }

 ConfirmarEmail(token,email){
  let data = {
    token,
    email
  }
  return this._http.post(`${this._config.URL_SERVICE_AUTH}/Auth/ConfirmarEmail`,data)
                  .subscribe((res:ResponseGeneral)=>{
                    this.ngZone.run(()=>{
                      if(res.codeError ==200){
                        this.NavCtrl.navigate(['/auth/login']);
                        this.success('success','Éxito',res.message);
                        this.showReset = false;
                      }else{
                        this.success('error','Intento Fallio',res.message);
                        this.showReset = false;
                         this.NavCtrl.navigate(['/auth/login']);
                      }
                    });

                });
}


   salirNavegador(){
    this.NavCtrl.navigate(['/auth/login']);
    this._config.UsuarioActivo = false;
    // this.success('success','Éxito','Mucha Suerte!');
    this._config.limpiarLocal();
    this.showLoader=false;
    this._config.IsTaller =false;
    this._config.CargadoCompleto =false;
   }
   success(type:string,title:string,text:string) {
    Swal.fire(
      title,
      text,
      type
    );
  }
   
}
