import { Pipe, PipeTransform } from '@angular/core';
import { IPayment, ListadoPagos, ReportePagos } from '../model/IReports';

@Pipe({
  name: 'sumatoriaFinal'
})
export class SumatoriaFinalPipe implements PipeTransform {

  transform(value: ListadoPagos, tipo: number, tipoMonto: number): string {
    let sumatoria = 0;
    if (tipoMonto == 2) {
      if (tipo == 0) {
        if (value.dataCompleta != null) {
          value.dataCompleta.forEach((element: ReportePagos) => {
            sumatoria = sumatoria + (parseFloat(element.amountEmpreTwo) + parseFloat(element.amountBanco));
          });
        } else if (value.dataDashBoard != null) {
          value.dataDashBoard.forEach((element: ReportePagos) => {
            sumatoria = sumatoria + (parseFloat(element.amountEmpreTwo) + parseFloat(element.amountBanco));
          });
        }
        return sumatoria.toFixed(2);
      }
      if (tipo == 1) {

        if (value.dataCompleta != null) {
          value.dataCompleta.forEach((element: ReportePagos) => {
            if (element.typeBuy == "1") {
              sumatoria = sumatoria + (parseFloat(element.amountEmpreOne));
            }
          });
        } else if (value.dataDashBoard != null) {
          value.dataDashBoard.forEach((element: ReportePagos) => {
            if (element.typeBuy == "1") {
              sumatoria = sumatoria + (parseFloat(element.amountEmpreOne));
            }
          });
        }

        return sumatoria.toFixed(2);
      }
      if (tipo == 2) {

        if (value.dataCompleta != null) {
          value.dataCompleta.forEach((element: ReportePagos) => {
            if (element.typeBuy == "2") {
              sumatoria = sumatoria + (parseFloat(element.amountEmpreOne));
            }
          });
        } else if (value.dataDashBoard != null) {
          value.dataDashBoard.forEach((element: ReportePagos) => {
            if (element.typeBuy == "2") {
              sumatoria = sumatoria + (parseFloat(element.amountEmpreOne));
            }
          });
        }
        return sumatoria.toFixed(2);
      }

      if (tipo == 3) {
        if (value.dataCompleta != null) {
          value.dataCompleta.forEach((element: ReportePagos) => {
            if (element.tipoPago == "VISA") {
              sumatoria = sumatoria + (parseFloat(element.amountEmpreTwo) + parseFloat(element.amountBanco));
            }
          });
        } else if (value.dataDashBoard != null) {
          value.dataDashBoard.forEach((element: ReportePagos) => {
            if (element.tipoPago == "VISA") {
              sumatoria = sumatoria + (parseFloat(element.amountEmpreTwo) + parseFloat(element.amountBanco));
            }
          });
        }
        return sumatoria.toFixed(2);
      }

      if (tipo == 4) {

        if (value.dataCompleta != null) {
          value.dataCompleta.forEach((element: ReportePagos) => {
            if (element.tipoPago == "CLAVE") {
              sumatoria = sumatoria + (parseFloat(element.amountEmpreTwo) + parseFloat(element.amountBanco));
            }
          });
        } else if (value.dataDashBoard != null) {
          value.dataDashBoard.forEach((element: ReportePagos) => {
            if (element.tipoPago == "CLAVE") {
              sumatoria = sumatoria + (parseFloat(element.amountEmpreTwo) + parseFloat(element.amountBanco));
            }
          });
        }
        return sumatoria.toFixed(2);
      }

      if (tipo == 5) {
        if (value.dataCompleta != null) {
          value.dataCompleta.forEach((element: ReportePagos) => {
            sumatoria = sumatoria + parseFloat(element.amount);
          });
        } else if (value.dataDashBoard != null) {
          value.dataDashBoard.forEach((element: ReportePagos) => {
            sumatoria = sumatoria + parseFloat(element.amount);
          });
        }
        return sumatoria.toFixed(2);
      }

      if (tipo == 6) {

        if (value.dataCompleta != null) {
          value.dataCompleta.forEach((element: ReportePagos) => {
            sumatoria = sumatoria + parseFloat(element.amountEmpreOne);
          });
        } else if (value.dataDashBoard != null) {
          value.dataDashBoard.forEach((element: ReportePagos) => {
            sumatoria = sumatoria + parseFloat(element.amountEmpreOne);
          });
        }

        return sumatoria.toFixed(2);
      }

    } else if (tipoMonto == 0) {
      if (tipo == 7) {
        value.dataCompleta.forEach((element: ReportePagos) => {
          sumatoria = sumatoria + element.lotBuy
        });
      }
      return sumatoria.toString();
    } else if (tipoMonto == 1) {
      if (tipo == 1) {
        value.dataDashBoard.forEach((element: IPayment) => {
          sumatoria = sumatoria + parseFloat(element.amount);
        });
        return sumatoria.toFixed(2);
      }

      if (tipo == 2) {
        value.dataDashBoard.forEach((element: IPayment) => {
          if (element.typeBuy == "1") {
            sumatoria = sumatoria + parseFloat(element.amount);
          }
        });
        return sumatoria.toFixed(2);
      }

      if (tipo == 3) {
        value.dataDashBoard.forEach((element: IPayment) => {
          if (element.typeBuy == "2") {
            sumatoria = sumatoria + parseFloat(element.amount);
          }
        });
        return sumatoria.toFixed(2);
      }
      if (tipo == 4) {
        value.dataDashBoard.forEach((element: IPayment) => {
          sumatoria = sumatoria + element.lotBuy
        });
        return sumatoria.toString();
      }

      if (tipo == 5) {
        value.dataDashBoard.forEach((element: IPayment) => {
          if (element.typeBuy == "1") {
            sumatoria = sumatoria + element.lotBuy;
          }
        });
        return sumatoria.toString();
      }

      if (tipo == 6) {
        value.dataDashBoard.forEach((element: IPayment) => {
          if (element.typeBuy == "2") {
            sumatoria = sumatoria + element.lotBuy;
          }
        });
        return sumatoria.toString();
      }

      if (tipo == 7) {
        value.dataDashBoard.forEach((element: IPayment) => {
          sumatoria = sumatoria + (parseFloat(element.amountEmpreOne));
        });
        return sumatoria.toFixed(2);
      }

      if (tipo == 8) {
        value.dataDashBoard.forEach((element: IPayment) => {
          sumatoria = sumatoria + (parseFloat(element.amountEmpreTwo) + parseFloat(element.amountBanco));
        });
        return sumatoria.toFixed(2);
      }

      if (tipo == 9) {
        value.dataDashBoard.forEach((element: IPayment) => {
          sumatoria = sumatoria + parseFloat(element.amountBanco);
        });
        return sumatoria.toFixed(2);
      }
      if (tipo == 10) {
        value.dataDashBoard.forEach((element: IPayment) => {
          sumatoria = sumatoria + parseFloat(element.amountEmpreTwo);
        });
        return sumatoria.toFixed(2);
      }
      if (tipo == 11) {
        value.dataDashBoard.forEach((element: IPayment) => {
          sumatoria = sumatoria + (parseFloat(element.amount) * 0.0248);
        });
        return sumatoria.toFixed(2);
      }
      if (tipo == 12) {
        value.dataDashBoard.forEach((element: IPayment) => {
          sumatoria = sumatoria + (parseFloat(element.amount) * 0.0252);
        });
        return sumatoria.toFixed(2);
      }

    }
  }

}
