<div class="auth-bg-video">
	<video id="bgvid" poster="assets/images/other-images/coming-soon-bg.jpg" playsinline="" autoplay="" muted=""
	loop="">
	<source src="assets/video/auth-bg.mp4" type="video/mp4">
  </video>
		<div class="authentication-box">
			<div class="card mt-4">
				<div class="card-body">
					<div class="text-center"> 
						<img src="/assets/images/logos/logo.png" alt="">
					</div>
					 <p class="text-center">Verificando el pago....</p>
				</div>
			</div>
		</div>
	</div> 
