import { Component } from '@angular/core';

@Component({
  selector: 'app-termino-condiciones',
  templateUrl: './termino-condiciones.component.html',
  styleUrls: ['./termino-condiciones.component.css']
})
export class TerminoCondicionesComponent {

}
