import { Pipe, PipeTransform } from '@angular/core';
import { ReportePagos } from '../model/IReports';

@Pipe({
  name: 'convertFloatSumatoria'
})
export class ConvertFloatSumatoriaPipe implements PipeTransform {

  transform(value: ReportePagos, tipo: number): string {
    var total = 0;
    if(tipo == 1) {
      total = parseFloat(value.amountEmpreTwo) + parseFloat(value.amountBanco);
    }else if (tipo == 2) {
       total = parseFloat(value.amount);
    }else if (tipo == 3) {
       total = parseFloat(value.amountEmpreOne);
    }else if(tipo == 0) {
      total = 0;
    }
    return `B/.${total.toFixed(2)}`;
  }

}
