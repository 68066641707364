<div class="auth-bg-video">
	<video id="bgvid" poster="assets/images/other-images/coming-soon-bg.jpg" playsinline="" autoplay="" muted=""
	loop="">
	<source src="assets/video/auth-bg.mp4" type="video/mp4">
  </video>
		<div class="authentication-box">
			<div class="card mt-4">
				<div class="card-body">
					<div class="text-center"> 
						<img src="/assets/images/logos/logo.png" alt="">
					</div>
					<form class="theme-form" [formGroup]="ResetForm">
						<div class="form-group">
							<label class="col-form-label pt-0">Correo Electrónico</label>
							<input class="form-control" formControlName="email" type="email" required="" placeholder="Correo Electrónico">
							<div *ngIf="ResetForm.controls.email.touched && ResetForm.controls.email.errors?.required"
								class="text text-danger mt-1">
								Correo electronico es Requerido
							</div>
						</div>
						<div class="form-group row mt-3 mb-0 text-center">
							<button class="btn btn-primary btn-block btn-lg" [class.loader--text]="authService.showReset"
								[disabled]="!ResetForm.valid || authService.showReset"  (click)="resetPassword()"
								type="submit"><span> {{ authService.showReset ? '' : 'Enviar Solicitud' }}</span>
							</button>
							<div class="login-divider"></div>
							<a [routerLink]="'/auth/login'" routerLinkActive="active" class="texto-login">
								Iniciar Sessión		
							</a>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>