import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { revisadoResponse } from '../model/ResponseRevisados';
@Pipe({
  name: 'formatDate'
})

export class FormatDatePipe implements PipeTransform {
  
  transform(value:any ): string {
    moment.locale('es');
    return moment(value).format('LLL');
  }

}
