import { Routes } from '@angular/router';
import { MantenimientoModulesModule } from '../../components/ATTT/mantenimiento/mantenimiento-modules.module';
export const content: Routes = [

  
  {
    path: 'Panel-control',
    loadChildren: () => import('../../components/ATTT/dashboard/dashboard-module.module').then(m => m.DashboardModuleModule),
    data: {
      breadcrumb: "Panel de Control"
    }
  },
  {
    path: 'Usuarios',
    loadChildren: () => import('../../components/ATTT/user-managenment/user-managenment.module').then(m => m.UserManagenmentModule),
    data: {
      breadcrumb: "Usuarios"
    }
  },
  {
    path: 'Talleres',
    loadChildren: () => import('../../components/ATTT/workshops-managenment/workshops-managenment.module').then(m => m.WorkshopsManagenmentModule),
    data: {
      breadcrumb: "Talleres"
    }
  },
  {
    path: 'Ajustes',
    loadChildren: () => import('../../components/ATTT/Configuracion/configuracion-attt.module').then(m => m.ConfiguracionAtttModule),

    data: {
      breadcrumb: "Solicitudes"
    }
  },
  {
    path: 'Revisados',
    loadChildren: () => import('../../components/ATTT/revised-managenment/revised-managenment-module.module').then(m => m.RevisedManagenmentModuleModule),
    data: {
      breadcrumb: "Revisados"
    }
  },
  {
    path: 'solicitudes',
    loadChildren: () => import('../../components/ATTT/complaint-managenment/complaint-managenment-module.module').then(m => m.ComplaintManagenmentModuleModule),
    data: {
      breadcrumb: "Denuncias"
    }
  },
  {
    path: 'Administrador',
    loadChildren: () => import('../../components/ATTT/Administrador/administrador.module').then(m => m.AdministradorModule),
    data: {
      breadcrumb: "Administrador"
    }
  },
  {
    path: 'Reportes',
    loadChildren: () => import('../../components/ATTT/Report/report-modules.module').then(m => m.ReportModulesModule),
    data: {
      breadcrumb: "Reportes"
    }
  },
  {
    path: 'Mantenimiento',
    loadChildren: () => import('../../components/ATTT/mantenimiento/mantenimiento-modules.module').then(m => m.MantenimientoModulesModule),
    data: {
      breadcrumb: "Mantenimiento"
    }
  },
  {
    path: 'pagos',
    loadChildren: () => import('../../components/ATTT/pagos/pagos-modules.module').then(m => m.PagosModulesModule),
    data: {
      breadcrumb: "Pagos"
    }
  }

];