<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright">
      <p class="mb-0">3TECH 2022 © Todos los derechos Reservados.</p>
    </div>
    <div class="col-md-6" style="text-align: right;">
      <img src="/assets/images/credicorp.png" alt="Cardy Style 7" class="imagenTC">
      <img src="/assets/images/LogoVisaMasterNew.png" alt="Cardy Style 7" class="imagenTCV">
    </div>
  </div>
</div>