import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'convertDateFirebase'
})
export class ConvertDateFirebasePipe implements PipeTransform {

  transform(value: any): string {
    const fireBaseTime = new Date(
      value.seconds * 1000 + value.nanoseconds / 1000000,
    );
    const date = fireBaseTime.toDateString();
    const atTime = fireBaseTime.toLocaleTimeString();

    moment.locale('es');
    return moment(fireBaseTime).format('LLL');
  }

}
