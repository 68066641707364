import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusPayments'
})
export class StatusPaymentsPipe implements PipeTransform {

  transform(value: number): string {
    if(value == 1){
      return 'Aprobada';
    }else{
      return  'Rechazada';
    }
  }

}
