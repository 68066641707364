import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ValidateBoletas } from 'src/app/shared/ATTT/model/XPayments';
import { ReportServicesService } from 'src/app/shared/ATTT/service/report-services.service';
declare var require
const Swal = require('sweetalert2');
@Component({
  selector: 'app-valid-payment',
  templateUrl: './valid-payment.component.html',
  styleUrls: ['./valid-payment.component.scss']
})
export class ValidPaymentComponent {
  public valor: string;
  public existe: boolean = false;
  public dataReturn: any;
  constructor(private rutaActiva: ActivatedRoute, private ruta: Router, private _payment:ReportServicesService) {

    this.valor= rutaActiva.snapshot.params.valor;
    if (this.valor == '') {
      ruta.navigate(['/auth/login']);
      localStorage.clear();
    } else {
      var cadena = this.replaceAll(this.valor, '~', '/');
      var cadenaFinal = this.replaceAll(cadena, '_', '+');
      let encrip = CryptoJS.AES.decrypt(cadenaFinal.trim(), 'Panama2019$').toString(CryptoJS.enc.Utf8);
      let resultado = encrip.split('|');
      if (resultado.length == 5) {
        this.dataReturn = {
          Id: resultado[0],
          Codigo: resultado[1],
          Taller: resultado[2],
          FechaRevisado: resultado[3],
          Monto: resultado[4],
        };
        
        _payment.initComprobantes();
        _payment.validComprobantes(this.dataReturn).subscribe(
          {
            next: (res:ValidateBoletas) => {
              if (res.status == 200) {
                _payment.ValidarComprobantes = res;
                this.existe = true;
                this.success('success','ECARCHECK',res.message)
              } else {
                this.success('warning','ECARCHECK',res.message)
               }
            },
            error: (err:any) => {
              this.existe = false;
              this.success('warning','ECARCHECK','Se presento problema al momento de validar el comprobante.')
            },
            complete: () => {
            }
          }
        ) 
      } else {
        ruta.navigate(['/auth/login']);
        localStorage.clear();
      }
      
    }

  }
  escapeRegExp(string:string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  replaceAll(str:string, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  ngOnInit(): void {
  }

  success(type:string,title:string,text:string) {
    Swal.fire(
      title,
      text,
      type
    );
  }

}
