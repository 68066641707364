import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceIdentityService } from 'src/app/shared/ATTT/service/auth-service-identity.service';
declare var require
const Swal = require('sweetalert2');

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public ResetForm: FormGroup;
  constructor( private NavCtrl:Router,private fb: FormBuilder,public authService:AuthServiceIdentityService) { 
    this.ResetForm = fb.group({
      email: ['..............', [Validators.required, Validators.email]],
    });
  }
  ngOnInit(): void {
  }
  resetPassword(){
     this.authService.RecuperarPassword(this.ResetForm.value);
  }


  /****alertas****/

  success(type:string,title:string,text:string) {
    Swal.fire(
      title,
      text,
      type
    );
  }


}
