import { Component } from '@angular/core';

@Component({
  selector: 'app-video-ecarcheck-components',
  templateUrl: './video-ecarcheck-components.component.html',
  styleUrls: ['./video-ecarcheck-components.component.css']
})
export class VideoEcarcheckComponentsComponent {

}
