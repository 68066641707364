<!-- Page Header Start-->
<div class="page-main-header" [ngClass]="{'open' : navServices.collapseSidebar}" id="page-main-header">
  <div class="main-header-right row">
    <div class="main-header-left col-auto p-0 d-lg-none">
      <div class="logo-wrapper"><a href="javascript:void(0)"><img src="/assets/images/logos/logo.png" alt=""></a></div>
    </div>
    <div class="mobile-sidebar col-auto p-0">
      <div class="media-body text-start switch-sm">
        <label class="switch">
          <a href="javascript:void(0)" (click)="collapseSidebar()">
            <app-feather-icons id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons>
          </a>
        </label>
      </div>
    </div>
    <div class="nav-right col">
      <ul class="nav-menus" [ngClass]="{'open' : openNav}">
        
        
        <li class="onhover-dropdown">
           
        </li>
        <!-- <li class="onhover-dropdown">
          <app-feather-icons [icon]="'bell'"></app-feather-icons><span class="dot"></span>
          <ul class="notification-dropdown onhover-show-div">
            <li>Notificación<span class="badge rounded-pill badge-primary pull-right">1</span></li>
            <li>
              <div class="media">
                <div class="flex-grow-1">
                  <h6 class="mt-0"><span>
                      <app-feather-icons class="shopping-color" [icon]="'shopping-bag'"></app-feather-icons>
                    </span>Nuevo Revisado Emitido!<small class="pull-right">9:00 AM</small></h6>
                  <p class="mb-0">Hola, se realizo el revisado # 2112</p>
                </div>
              </div>
            </li>
            <li class="bg-light txt-dark"><a href="javascript:void(0)">Ver</a> Todas las Notificaciones</li>
          </ul>
        </li> -->
        <li class="onhover-dropdown">
          <!--  *ngIf="!authService.userData as user" -->
          <div class="media align-items-center"><img
              class="align-self-center pull-right img-50 rounded-circle" src="assets/images/final.png"
              alt="header-user">
            <div class="dotted-animation"><span class="animate-circle"></span><span class="main-circle"></span></div>
          </div>
          <!-- *ngIf="authService.userData as user" -->
          <!-- <div class="media align-items-center" ><img
              class="align-self-center pull-right img-50 rounded-circle"
              [src]="user.photoURL ==null ? 'assets/images/final.png' : user.photoURL" alt="header-user">
            <div class="dotted-animation"><span class="animate-circle"></span><span class="main-circle"></span></div>
          </div> -->
          <ul class="profile-dropdown onhover-show-div p-20">
            <!-- <li><a href="javascript:void(0)">
                <app-feather-icons [icon]="'user'"></app-feather-icons>Editar Perfil
              </a></li> -->
            <!-- <li><a href="javascript:void(0)">
                <app-feather-icons [icon]="'mail'"></app-feather-icons>Mensajes
              </a></li> -->
              <!-- (click)="authService.SignOut() -->
            <li *ngIf="_config.UsuarioActivo"><a href="javascript:void(0)" (click)="authService.salirNavegador()">
                <app-feather-icons [icon]="'log-out'"></app-feather-icons>Salir
              </a></li>
          </ul>
        </li>
      </ul>
      <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
        <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
      </div>
    </div>
  </div>
</div>
<!-- Page Header Ends -->