<!--error-400 start-->
<div class="error-wrapper">
  <div class="container">
    <img class="img-100" src="assets/images/other-images/sad.png" alt="">
    <div class="error-heading">
      
      <h2 class="headline font-info">400</h2>      
    </div>
    <div class="col-md-8 offset-md-2">
      <p class="sub-content">La página a la que intenta acceder no está disponible actualmente. Esto puede deberse a que la página no existe o se ha movido.</p>
    </div>
    <div class="">
      <a [routerLink]="'/auth/login'" class="btn btn-info-gradien btn-lg">PAGINA DE INICIO</a>
    </div>
  </div>
</div>
<!--error-400 end-->