// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCJi0cQ866lzPR8hYJUs77JUmD4DBpswl8",
    authDomain: "angular-deomo.firebaseapp.com",
    databaseURL: "https://angular-deomo.firebaseio.com",
    projectId: "angular-deomo",
    storageBucket: "angular-deomo.appspot.com",
    messagingSenderId: "175907956497"
  },
  Services:{
    Auth:"http://localhost:5097/api/v1/ServiceUser",
    Revisados:"http://localhost:7192/api/v1/",
    Taller:"http://localhost:42515/api/v1",
    Maestras:"http://localhost:7268/api/v1",
    Verificacion:"http://localhost:4200/Verificar/",
    MyPayment:"http://localhost:5035/Api/",
  }
};
/*
 * http://localhost:4200/Verificar/232/323
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
