import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'multipropositosPipe'
})
export class MultipropositosPipePipe implements PipeTransform {

  transform(value: any, tipo: number): any {
    if (tipo == 1) {
      let valor = parseFloat(value);
     return `B/.${valor.toFixed(2)}`
    }else if (tipo ==2) {
      if (value == 1 || value == 0) {
        return 'Pendiente';
      } else if (value == 2) {
        return 'En Revisión';
      } else if (value == 3) {
        return 'Rechazado';
      } else if (value == 4) {
        return 'Aprobado';
      }
    } else if (tipo == 3) {
        moment.locale('es');
        return moment(value).format('LL');
    }
  }

}
