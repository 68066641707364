import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { DecimalPipe } from '@angular/common';

import { AuthService } from './shared/services/firebase/auth.service';
import { AdminGuard } from './shared/guard/admin.guard';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";

import { environment } from '../environments/environment.prod';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { PanelControlComponent } from './components/ATTT/dashboard/panel-control/panel-control.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ForgetPwdComponent } from './auth/forget-pwd/forget-pwd.component';
import { VerificarMailComponent } from './auth/verificar-mail/verificar-mail.component';
import { PoliticaPrivacidadComponent } from './components/ATTT/Politicas/politica-privacidad/politica-privacidad.component';
import { PoliticaDevolucionComponent } from './components/ATTT/Politicas/politica-devolucion/politica-devolucion.component';
import { TerminoCondicionesComponent } from './components/ATTT/Politicas/termino-condiciones/termino-condiciones.component';
import { VerifyDocumentoComponent } from './components/ATTT/Checker/verify-documento/verify-documento.component';
import { VideoEcarcheckComponentsComponent } from './components/ATTT/video-ecarcheck-components/video-ecarcheck-components.component';
import { GetPaymentsClaveComponent } from './components/ATTT/get-payments-clave/get-payments-clave.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { ValidPaymentComponent } from './components/ATTT/Checker/valid-payment/valid-payment.component';
import { VerificarPreComponent } from './components/ATTT/Checker/verificar-pre/verificar-pre.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgetPwdComponent,
    VerificarMailComponent,
    PoliticaPrivacidadComponent,
    PoliticaDevolucionComponent,
    TerminoCondicionesComponent,
    VerifyDocumentoComponent,
    VideoEcarcheckComponentsComponent,
    GetPaymentsClaveComponent,
    MaintenanceComponent,
    ValidPaymentComponent,
    VerificarPreComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
  ],
  providers: [AuthService, AdminGuard, SecureInnerPagesGuard, CookieService, DecimalPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
