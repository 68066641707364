<div class="auth-bg-video">
    <video id="bgvid" poster="assets/images/other-images/coming-soon-bg.jpg" playsinline="" autoplay="" muted=""
        loop="">
        <source src="/assets/video/auth-bg.mp4" type="video/mp4">
    </video>
    <div class="">
        <div class="card">
            <div class="card-body">
                <div class="text-center">
                    <img src="/assets/images/logos/logo.png" alt="">
                </div>
                <div class="" style="    background: #0080005c;padding: 25px;border-radius: 20px;color: #100e0e;" *ngIf="existe">
                    <P style="font-size: 17px;" >N.o de Comprobante: <strong>{{ dataReturn.Id }}</strong></P>
                    <P style="font-size: 17px;" >Código de Taller: <strong>{{ dataReturn.Codigo }}</strong></P>
                    <P style="font-size: 17px;" >Nombre del Taller: <strong>{{ dataReturn.Taller }}</strong></P>
                    <P style="font-size: 17px;" >Monto: <strong>B./ {{ dataReturn.Monto }}</strong></P>
                    <P style="font-size: 17px;" >Fecha y hora de la compra: <strong>{{ dataReturn.FechaRevisado }}</strong></P>
                    <hr>
                    <p style="text-align: justify;font-size: 17px;text-transform: uppercase;">{{_payment.ValidarComprobantes?.message}}</p>
                </div>

                <div class="alert alert-danger" *ngIf="!existe">
                    <h5>VALIDACIÓN DEL COMPROBANTE</h5>
                    <hr>
                    <p style="text-align: justify;">{{_payment.ValidarComprobantes?.message}}</p>
                </div>

            </div>
        </div>
    </div>
</div>