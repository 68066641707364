import { Injectable } from '@angular/core';
import { LoginResponse, TokenIn } from '../model/LoginRequest';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';  
import jwt_decode from 'jwt-decode';
import { Token } from '@angular/compiler';
import { environment } from '../../../../environments/environment.prod';
import { TallerResponsable, TallerEstacionTrabajo, Taller, TallerCompraCupo } from '../model/RequestTallerR';
import { TallerServiceService } from '../service/taller-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var require
const Swal = require('sweetalert2');
@Injectable({
  providedIn: 'root'
})
export class ConfigServiceService {

  public latitude=0;
  public longitude=0;
  public URL_SERVICE_AUTH = '';
  public URL_SERVICE_REVISADO = '';
  public URL_SERVICE_PHOTO = '';
  public URL_SERVICE_TALLER = '';
  public URL_SERVICE_MAESTRAS = '';
  public URL_SERVICE_PAYMENTS = '';
  public URL_SERVICE_LOG = '';
  public URL_SERVICE_MANTENIMIENTO = '';
  public URL_SERVICE_PREREVISADO = '';
  public URL_SERVICE_PREREVISADO_UPLOAD = '';
  public URL_SERVICE_ACH = '';
  
  public CargadoCompleto:boolean=false;
  /*********Usuario******** */
  public Token = '';
  public Id_Usuario='';
  public nonbreUsuario ='';
  public rol='';
  public correo='';
  public UsuarioActivo = false;
  public IsTaller:boolean =false;
  public info:TokenIn={
    nameid:                                                        '',
    email:                                                         '',
    name:                                                          '',
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role":'',
    exp:                                                           0,
  }
  public TallerUserActivo:TallerResponsable;
  public PuestoTrabajoActivo:TallerEstacionTrabajo;
  public TallerActivo:Taller;
  public CuposTaller:TallerCompraCupo;
  public CuposTallerComercial:TallerCompraCupo;
  conversionEncryptOutput: string;  
  conversionDecryptOutput:string; 
  constructor(private NavCtrl:Router,private _http:HttpClient) {
    this.URL_SERVICE_AUTH =  environment.Services.Auth;
    this.URL_SERVICE_REVISADO= environment.Services.Revisados+'ServiceRevisado';
    this.URL_SERVICE_PHOTO= environment.Services.Revisados+'Socket';
    this.URL_SERVICE_TALLER = environment.Services.Taller;
    this.URL_SERVICE_MAESTRAS = environment.Services.Maestras;
    this.URL_SERVICE_PAYMENTS = environment.Services.MyPayment;
    this.URL_SERVICE_LOG = environment.Services.ApiLog;
    this.URL_SERVICE_MANTENIMIENTO = environment.Services.ApiMantenimiento;
    this.URL_SERVICE_PREREVISADO = environment.Services.ApiPreRevisados+'CatalogoWSControllers/';
    this.URL_SERVICE_PREREVISADO_UPLOAD = environment.Services.ApiPreRevisados + 'UploadFotoControllers/';
    this.URL_SERVICE_ACH = environment.Services.ACHService;
    this.InitCupos();

   }
  


   setUserLocal(userData:LoginResponse){
    let usuario = JSON.stringify(userData);
    this.conversionEncryptOutput = CryptoJS.AES.encrypt(usuario, 'Panama2019$').toString();  
    this.conversionEncryptOutput.replace('/','_');
    this.conversionEncryptOutput.replace('+','`~`');
    this.info = jwt_decode(userData.token);    
    this.Token = userData.token;
    this.UsuarioActivo=true;
    localStorage.setItem('user',this.conversionEncryptOutput );
    this.rol = this.info['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    if(this.rol=='Taller'){
      this.IsTaller=true;
     }
     console.log('====================================');
     console.log(this.rol);
     console.log('====================================');
   }
  getUserLocal() { 
     try {
      let activo = localStorage.getItem('user');
      if (activo == '' || activo == null || activo == undefined) {
        this.UsuarioActivo = false;
        this.NavCtrl.navigate(['/auth/login']);
        return null;
      } else {
        activo.replace('_','/');
        activo.replace('~','`+`');
        
        let encrip = CryptoJS.AES.decrypt(activo.trim(), 'Panama2019$').toString(CryptoJS.enc.Utf8); 
        let user:LoginResponse = JSON.parse(encrip);
        this.info = jwt_decode(user.token);
        this.verficarSession(activo);
        this.Token = user.token;
        this.UsuarioActivo =true;
        this.rol = this.info['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        if(this.rol=='Taller'){
          this.IsTaller=true;
        }
        return user;
      }
     } catch (error) {
       this.NavCtrl.navigate(['/auth/login']);
       this.limpiarLocal();
     }
   }

   verficarSession(usuario){
    if(usuario == '' || usuario == null || usuario == undefined){
      this.UsuarioActivo = false;
      this.NavCtrl.navigate(['/auth/login']);
    }
    if ((Math.floor((new Date).getTime() / 1000)) >= (this.info.exp-500)) {
      if ((Math.floor((new Date).getTime() / 1000)) >= (this.info.exp)) {
        this.limpiarLocal();
        this.UsuarioActivo = false;
        this.NavCtrl.navigate(['/auth/login']);
      }else{
        //enviar generacion token.....
        let data = {
          Email:this.info.email,
          Id:this.info.nameid,
          Token:this.Token
        }
        this.generarToken(data);
      }
    }else if(this.info.exp == 0){
     
      this.limpiarLocal();
      this.UsuarioActivo = false;
      this.NavCtrl.navigate(['/auth/login']);
    }
    
    
   }

   generarToken(data){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.Token}`
    });
    const requestOptions = { headers: headers };
    return this._http.post(`${this.URL_SERVICE_AUTH}/GetNeWToken`,data,requestOptions).subscribe({
      next:(res:any)=>{
        this.Token = res.tokenGenerado;
        let activo = localStorage.getItem('user');
        let encrip = CryptoJS.AES.decrypt(activo.trim(), 'Panama2019$').toString(CryptoJS.enc.Utf8); 
        let user:LoginResponse = JSON.parse(encrip);
        user.token = res.tokenGenerado;

        let usuario = JSON.stringify(user);
        this.conversionEncryptOutput = CryptoJS.AES.encrypt(usuario, 'Panama2019$').toString();  
        this.conversionEncryptOutput.replace('/','_');
        this.conversionEncryptOutput.replace('+','`~`');
        localStorage.setItem('user',this.conversionEncryptOutput );
      }
    });
   }

   ValidarTallerActivo(){
   }

   InitCupos(){
    this.CuposTaller={
      id:0,
      fechaCreacion:new Date(),
      creadoPor:this.info.email,
      fechaUltimaModificacion:new Date(),
      usuarioUltimaModificacion:'',
      tallerId:0,
      cantidad:0,
      usados:0,
      disponibles:0,
  };

  this.CuposTallerComercial={
    id:0,
    fechaCreacion:new Date(),
    creadoPor:this.info.email,
    fechaUltimaModificacion:new Date(),
    usuarioUltimaModificacion:'',
    tallerId:0,
    cantidad:0,
    usados:0,
    disponibles:0,
};

   }

   getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resp => {
                resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
            },
            err => {
                reject(err);
          });
    });
}


   limpiarLocal(){
    localStorage.clear();
   }
}

