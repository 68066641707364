import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from '../../shared/services/firebase/auth.service';
// finales
import { AuthServiceIdentityService } from '../../shared/ATTT/service/auth-service-identity.service';
import { LoginRequest } from '../../shared/ATTT/model/LoginRequest';
import { jsPDF } from "jspdf";
import QrCodeWithLogo from "qrcode-with-logos";
import { RevisadoServiceService } from '../../shared/ATTT/service/revisado-service.service';
import { CookieService } from 'ngx-cookie-service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifyFirebaseService } from '../../shared/ATTT/service/notify-firebase.service';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('content') templateRef: TemplateRef<any>;
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    'email': '',
    'password': '',
  };
  public loginReq:LoginRequest ={
    email:'',
    password:''
  };
  public errorMessage: any;

  constructor(public authService: AuthService,
    private afauth: AngularFireAuth, private fb: FormBuilder,
    private AuthIdentity:AuthServiceIdentityService,public config: NgbModalConfig, private modalService: NgbModal,
    private router: Router,private cookieService:CookieService, private _noti:NotifyFirebaseService) {
      config.backdrop = 'static';
      config.keyboard = false;
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    localStorage.clear();
    // _noti.createNotificacion();
  }
  ngAfterViewInit(): void {
    let valor=this.getCookie();
    
    if(valor ==''){
      this.modalService.open(this.templateRef, { size: 'lg',animation:true});
    }
    
  }
  ngOnInit() {
  }
  setCookie(){
    const agent = window.clientInformation;
    this.cookieService.set('browser',agent.userAgent);
    this.cookieService.set('language',agent.language);
    this.cookieService.set('vendor',agent.vendor);
    this.cookieService.set('platform',agent.platform);
  }

  deleteCookie(){
    this.cookieService.deleteAll();
  }

  getCookie(){
    this.cookieService.get('browser');
    this.cookieService.get('language');
    this.cookieService.get('vendor');
    return this.cookieService.get('platform');
  }
  aceptarCo(){
    this.setCookie();
    this.modalService.dismissAll();
  }
  // Login With Google
  loginGoogle() {
    this.authService.GoogleAuth();
  }

  // Login With Twitter
  loginTwitter(): void {
    this.authService.signInTwitter();
  }

  // Login With Facebook
  loginFacebook() {
    this.authService.signInFacebok();
  }

  // Simple Login
  async login() {

    this.loginReq = await this.loginForm.value;
    this.AuthIdentity.AuthUser(this.loginReq);
    // this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
  }
}
