import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusTypeCupos'
})
export class StatusTypeCuposPipe implements PipeTransform {

  transform(value:any): string {
    if(value == 1){
      return 'Cupos Particular';
    }else if(value == 2){
      return 'Cupos Transporte';
    }else{
      return  '';
    }
  }

}
