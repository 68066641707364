import { Directive,EventEmitter,Output,HostListener,HostBinding } from '@angular/core';

@Directive({
  selector: '[appDragDropFiles]'
})
export class DragDropFilesDirective {

  constructor(){
    alert("ddd....");
  }
  @Output() fileDropped = new EventEmitter<any>();
  @Output() fileHovered = new EventEmitter();
  @HostBinding('style.background-color') private background = 'red';
  // Dragover Event
  @HostListener('dragover', ['$event']) public dragOver(event: any) {;
    
    event.preventDefault();
    event.stopPropagation();
    this.background = '#e2eefd';
    this.fileHovered.emit(true);
  }
  // Dragleave Event
  @HostListener('dragleave', ['$event']) public dragLeave(event: any) {;
    
    event.preventDefault();
    event.stopPropagation();
    this.background = '#ffffff';
    this.fileHovered.emit(true);
  }
  // Drop Event
  @HostListener('drop', ['$event']) public drop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#ffffff';
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
    this.fileHovered.emit(false);
  }
}
