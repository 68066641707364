import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ContentLayoutComponent } from './components/layout/content-layout/content-layout.component';
import { FullLayoutComponent } from './components/layout/full-layout/full-layout.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { BookmarkComponent } from './components/bookmark/bookmark.component';
import { TranslateModule } from '@ngx-translate/core';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { DragulaModule } from 'ng2-dragula';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import 'hammerjs';
import 'mousetrap';
// services
import { NavService } from "./services/nav.service";
import { ChatService } from "./services/chat.service";
import { CustomizerService } from "./services/customizer.service";
import { TableService } from './services/table.service';
// Directives
import { ToggleFullscreenDirective } from "./directives/fullscreen.directive";
import { NgbdSortableHeader } from './directives/NgbdSortableHeader';
import { FormatDatePipe } from './ATTT/pipe/format-date.pipe';
// servicios finales
import { AuthServiceIdentityService } from './ATTT/service/auth-service-identity.service';
import { ChangeStatusPipe } from './ATTT/pipe/change-status.pipe';
import { StatusBinariosPipe } from './ATTT/pipe/status-binarios.pipe';
import { DragDropFilesDirective } from './ATTT/directivas/drag-drop-files.directive';
import { StatusTypeCuposPipe } from './ATTT/pipe/status-type-cupos.pipe';
import { StatusPaymentsPipe } from './ATTT/pipe/status-payments.pipe';
import { ObjectarrayPipe } from './ATTT/pipe/objectarray.pipe';
import { ConvertFloatSumatoriaPipe } from './ATTT/pipe/convert-float-sumatoria.pipe';
import { SumatoriaFinalPipe } from './ATTT/pipe/sumatoria-final.pipe';
import { ConvertDateFirebasePipe } from './ATTT/pipe/convert-date-firebase.pipe';
import {  IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MultipropositosPipePipe } from './ATTT/pipe/multipropositos-pipe.pipe'


@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BookmarkComponent,
    RightSidebarComponent,
    ContentLayoutComponent,
    FullLayoutComponent,
    FeatherIconsComponent,
    ToggleFullscreenDirective,
    NgbdSortableHeader,
    BreadcrumbComponent,
    CustomizerComponent,
    FormatDatePipe,
    ChangeStatusPipe,
    StatusBinariosPipe,
    DragDropFilesDirective,
    StatusTypeCuposPipe,
    StatusPaymentsPipe,
    ObjectarrayPipe,
    ConvertFloatSumatoriaPipe,
    SumatoriaFinalPipe,
    ConvertDateFirebasePipe,
    MultipropositosPipePipe
    
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    DragulaModule.forRoot(),
    NgbModule,
  ],
  exports: [
    LoaderComponent,
    FeatherIconsComponent,
    TranslateModule,
    NgbdSortableHeader,
    FormatDatePipe,
    ChangeStatusPipe,
    StatusBinariosPipe,
    StatusTypeCuposPipe,
    StatusPaymentsPipe,
    ConvertFloatSumatoriaPipe,
    SumatoriaFinalPipe,
    ConvertDateFirebasePipe,
    MultipropositosPipePipe
  ],
  providers: [
    ChatService,
    CustomizerService,
    TableService, 
    // servicios finales
    AuthServiceIdentityService,
    NavService,
    StatusTypeCuposPipe,
    StatusPaymentsPipe,
    ConvertDateFirebasePipe,
  ]
})
export class SharedModule { }

