import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ContentLayoutComponent } from './shared/components/layout/content-layout/content-layout.component';
import { FullLayoutComponent } from './shared/components/layout/full-layout/full-layout.component';
import { content } from "./shared/routes/content-routes";
import { full } from './shared/routes/full.routes';
import { AdminGuard } from './shared/guard/admin.guard';

import { ForgetPwdComponent } from './auth/forget-pwd/forget-pwd.component';
import { VerificarMailComponent } from './auth/verificar-mail/verificar-mail.component';
import { Error400Component } from './pages/error-pages/error400/error400.component';
import { PoliticaPrivacidadComponent } from './components/ATTT/Politicas/politica-privacidad/politica-privacidad.component';
import { PoliticaDevolucionComponent } from './components/ATTT/Politicas/politica-devolucion/politica-devolucion.component';
import { TerminoCondicionesComponent } from './components/ATTT/Politicas/termino-condiciones/termino-condiciones.component';
import { VerifyDocumentoComponent } from './components/ATTT/Checker/verify-documento/verify-documento.component';
import { VideoEcarcheckComponentsComponent } from './components/ATTT/video-ecarcheck-components/video-ecarcheck-components.component';
import { GetPaymentsClaveComponent } from './components/ATTT/get-payments-clave/get-payments-clave.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { ValidPaymentComponent } from './components/ATTT/Checker/valid-payment/valid-payment.component';
import { VerificarPreComponent } from './components/ATTT/Checker/verificar-pre/verificar-pre.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'Panel-control',
    pathMatch: 'full'
  },
  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: 'auth/reset-password',
    component: ResetPasswordComponent
  },
  {
    path:'auth/verificar/:id/:email',
    component: VerificarMailComponent

  },
  {
    path:'auth/recuperar/:id/:email',
    component: ForgetPwdComponent
  },
  {
    path:'Politica-Privacidad',
    component: PoliticaPrivacidadComponent

  },
  {
    path:'Politica-Devolución',
    component: PoliticaDevolucionComponent

  },
  {
    path:'Informacion',
    component: MaintenanceComponent

  },
  {
    path:'Termino-Condiciones',
    component: TerminoCondicionesComponent

  },
  {
    path:'Verificar/:valor',
    component: VerifyDocumentoComponent

  },
   {
    path:'VerificarPre/:valor',
    component: VerificarPreComponent

  },
  {
    path:'ValidadPago/:valor',
    component: ValidPaymentComponent

  },
  {
    path:'Tutorial/Video',
    component: VideoEcarcheckComponentsComponent

  },
  {
    path:'GetPaymentsClave',
    component: GetPaymentsClaveComponent

  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AdminGuard],
    children: content
  },
  {
    path: '',
    component: FullLayoutComponent,
    // canActivate: [AdminGuard],
    children: full
  },
  {
    path: '**',
    component: Error400Component,
  }
];



@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
