import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, zoomOut, zoomIn, fadeIn, bounceIn } from 'ng-animate';
import { NavService } from '../../../services/nav.service';
import { CustomizerService } from '../../../services/customizer.service';
import * as feather from 'feather-icons';
import { ConfigServiceService } from 'src/app/shared/ATTT/config/config-service.service';
import { AuthServiceIdentityService } from '../../../ATTT/service/auth-service-identity.service';
import { NotifyFirebaseService } from 'src/app/shared/ATTT/service/notify-firebase.service';
import { map } from 'rxjs';
declare var require
const Swal = require('sweetalert2');
@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  animations: [
    trigger('animateRoute', [transition('* => *', useAnimation(fadeIn, {
      // Set the duration to 5seconds and delay to 2 seconds
      //params: { timing: 3}
    }))])
  ]
})
export class ContentLayoutComponent implements OnInit, AfterViewInit {


  public right_side_bar: boolean;
  public colorFondo: string = '#2f3c4e';
  public colorLetra: string = 'white';
  public dataFirestore: any = [];
  public cantidad: number = 0;
  constructor(public navServices: NavService,public Service:ConfigServiceService,
    public customizer: CustomizerService,public serviceUser:AuthServiceIdentityService, public _noti:NotifyFirebaseService) { }


  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  @HostListener('document:click', ['$event'])
  clickedOutside(event) {
    
    this.Service.getUserLocal();
    if (this.Service.IsTaller) {
      this.serviceUser.ValidarTallerActivo();
     
    }
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public rightSidebar($event) {
    this.right_side_bar = $event
  }

   ngOnInit() { 
    this.Service.getUserLocal();
    if (this.Service.IsTaller) {
        this.serviceUser.ValidarTallerActivo();
      this._noti.getNotificacionActiva().subscribe((res) => {
        this.dataFirestore = res;
        this.cantidad = 0;
        res.forEach(element => {
          if (element.payload.doc.data()['all'] == 0) {
            element.payload.doc.data()['taller'].forEach(elementItem => {
              if (this.Service.TallerActivo.id == elementItem.TallerI) {
                this.cantidad++;
              }
            });
          } else if (element.payload.doc.data()['all'] == 1) {
            this.cantidad++;
          }
        });
     });
    }
  }



}
