<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
            <div class="card-header">
                <h5>Video Tutorial</h5>
            </div>
            <div class="card-body">
                <div class="card-block row">
                    <video src="https://revisado-imagenes.s3.amazonaws.com/video/Video.mp4" autoplay controls></video>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>



  <!-- Container-fluid Ends-->