<div class="auth-bg-video">
	<video id="bgvid" poster="assets/images/other-images/coming-soon-bg.jpg" playsinline="" autoplay="" muted=""
	loop="">
	<source src="assets/video/auth-bg.mp4" type="video/mp4">
  </video>
		<div class="authentication-box">
			<div class="card mt-4">
				<div class="card-body">
					<div class="text-center">
						<!-- <h5>INICIO DE SESIÓN</h5> -->
						<img src="/assets/images/logos/logo.png" alt="">
						<!-- <h6>INGRESE SUS CREDENCIALES</h6> -->
					</div>
					<form class="theme-form" [formGroup]="loginForm" *ngIf="!newUser">
						<div class="form-group">
							<label class="col-form-label pt-0">Usuario</label>
							<input class="form-control" formControlName="email" type="email" required="" placeholder="Correo Electrónico">
							<div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
								class="text text-danger mt-1">
								Usuario es Requerido
							</div>
							<div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
								class="text text-danger mt-1">
								Correo Eletrónico Incorrecto
							</div>
						</div>
						<div class="form-group">
							<label class="col-form-label">Contraseña</label>
							<input class="form-control" type="password" formControlName="password" required="" placeholder="********">
							<div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
								class="text text-danger mt-1">
								Contraseña es Requerido
							</div>
						</div>
						<div class="checkbox p-0">
							<input id="checkbox1" type="checkbox">
							<label for="checkbox1">Recordar Cuenta</label>
						</div>
						<div class="form-group row mt-3 mb-0 text-center">
							<button class="btn btn-primary btn-block btn-lg"
								[class.loader--text]="AuthIdentity.showLoader"
								[disabled]="!loginForm.valid || AuthIdentity.showLoader" (click)="login(loginForm.value)"
								type="submit"><span> {{ AuthIdentity.showLoader ? '' : 'Ingresar' }} </span>
							</button>
							<div class="login-divider"></div>
							<a [routerLink]="'/auth/reset-password'" routerLinkActive="active" class="texto-olvido-contrasena">
								Olvido su contraseña?		
							</a>
							<a href="https://exam.ecarcheck.net/" target="_blank" routerLinkActive="active" class="texto-olvido-certi">
								Certifiación Ecarcheck
							</a>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>

	<ng-template #content let-modal>
        <div class="modal-header header_modal">
			<h6>POLITICA DE COOKIES</h6>
        </div>
        <div class="modal-body politica">
			<p style="text-align: justify;">Cookie es un fichero que se descarga en su ordenador al acceder a determinadas p&aacute;ginas web. Las cookies permiten a una p&aacute;gina web, entre otras cosas, almacenar y recuperar informaci&oacute;n sobre los h&aacute;bitos de navegaci&oacute;n de un usuario o de su equipo y, dependiendo de la informaci&oacute;n que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario. El navegador del usuario memoriza cookies en el disco duro solamente durante la sesi&oacute;n actual ocupando un espacio de memoria m&iacute;nimo y no perjudicando al ordenador. Las cookies no contienen ninguna clase de informaci&oacute;n personal espec&iacute;fica, y la mayor&iacute;a de estas se borran del disco duro al finalizar la sesi&oacute;n de navegador (las denominadas cookies de sesi&oacute;n).</p>
			<p style="text-align: justify;">La mayor&iacute;a de los navegadores aceptan como est&aacute;ndar a las cookies y, con independencia de estas, permiten o impiden en los ajustes de seguridad las cookies temporales o memorizadas.</p>
			<p style="text-align: justify;">Sin su expreso consentimiento&ndash;mediante la activaci&oacute;n de las cookies en su navegador, no enlazar&aacute; en las cookies los datos memorizados con sus datos personales proporcionados en el momento del registro o la compra.</p>
			<p style="text-align: justify;"><strong>&iquest;QU&Eacute; TIPOS DE COOKIES UTILIZA ESTA P&Aacute;GINA WEB?</strong></p>
			<p style="text-align: justify;">-&nbsp;&nbsp;<strong>Cookies t&eacute;cnicas</strong>: Son aqu&eacute;llas que permiten al usuario la navegaci&oacute;n a trav&eacute;s de una p&aacute;gina web, plataforma o aplicaci&oacute;n y la utilizaci&oacute;n de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tr&aacute;fico y la comunicaci&oacute;n de datos, identificar la sesi&oacute;n, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripci&oacute;n o participaci&oacute;n en un evento, utilizar elementos de seguridad durante la navegaci&oacute;n, almacenar contenidos para la difusi&oacute;n de videos o sonido o compartir contenidos a trav&eacute;s de redes sociales.</p>
			<p style="text-align: justify;">-&nbsp;&nbsp;<strong>Cookies de personalizaci&oacute;n</strong>: Son aqu&eacute;llas que permiten al usuario acceder al servicio con algunas caracter&iacute;sticas de car&aacute;cter general predefinidas en funci&oacute;n de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a trav&eacute;s del cual accede al servicio, la configuraci&oacute;n regional desde donde accede al servicio, etc.</p>
			<p style="text-align: justify;">-&nbsp;&nbsp;<strong>Cookies de an&aacute;lisis</strong>: Son aqu&eacute;llas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el n&uacute;mero de usuarios y as&iacute; realizar la medici&oacute;n y an&aacute;lisis estad&iacute;stico de la utilizaci&oacute;n que hacen los usuarios del servicio ofertado. Para ello se analiza su navegaci&oacute;n en nuestra p&aacute;gina web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.</p>
			<p style="text-align: justify;">-&nbsp;&nbsp;<strong>Cookies publicitarias:</strong>&nbsp;Son aqu&eacute;llas que, bien tratadas por nosotros o por terceros, nos permiten gestionar de la forma m&aacute;s eficaz posible la oferta de los espacios publicitarios que hay en la p&aacute;gina web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra p&aacute;gina web. Para ello podemos analizar sus h&aacute;bitos de navegaci&oacute;n en Internet y podemos mostrarle publicidad relacionada con su perfil de navegaci&oacute;n.</p>
			<p style="text-align: justify;">-&nbsp;&nbsp;<strong>Cookies de publicidad comportamental</strong>: Son aqu&eacute;llas que permiten la gesti&oacute;n, de la forma m&aacute;s eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una p&aacute;gina web, aplicaci&oacute;n o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan informaci&oacute;n del comportamiento de los usuarios obtenida a trav&eacute;s de la observaci&oacute;n continuada de sus h&aacute;bitos de navegaci&oacute;n, lo que permite desarrollar un perfil espec&iacute;fico para mostrar publicidad en funci&oacute;n del mismo.</p>
			<p style="text-align: justify;"><strong>-&nbsp; Cookies de terceros:</strong>&nbsp;La Web&nbsp;<strong><u>www.ecarcheck.net</u></strong><strong>&nbsp;</strong> puede utilizar servicios de terceros que, por cuenta de&nbsp;<strong><u>www.ecarcheck.net</u></strong><strong>&nbsp;</strong>, recopilaran informaci&oacute;n con fines estad&iacute;sticos, de uso del Site por parte del usuario y para la prestaci&oacute;n de otros servicios relacionados con la actividad del Website y otros servicios de Internet.</p>
			<p style="text-align: justify;">En particular, este sitio Web utiliza&nbsp;<strong>Google Analytics</strong>, un servicio anal&iacute;tico de web prestado por&nbsp;<strong>Google, Inc</strong>. con domicilio en los Estados Unidos con sede central en 1600 Amphitheatre Parkway, Mountain View, California 94043. Para la prestaci&oacute;n de estos servicios, estos utilizan cookies que recopilan la informaci&oacute;n, incluida la direcci&oacute;n IP del usuario, que ser&aacute; transmitida, tratada y almacenada por Google en los t&eacute;rminos fijados en la Web Google.com. Incluyendo la posible transmisi&oacute;n de dicha informaci&oacute;n a terceros por razones de exigencia legal o cuando dichos terceros procesen la informaci&oacute;n por cuenta de Google.</p>
			<p style="text-align: justify;"><strong>El Usuario acepta expresamente, por la utilizaci&oacute;n de este Site, el tratamiento de la informaci&oacute;n recabada en la forma y con los fines anteriormente mencionados.&nbsp;</strong>Y asimismo reconoce conocer la posibilidad de rechazar el tratamiento de tales datos o informaci&oacute;n rechazando el uso de Cookies mediante la selecci&oacute;n de la configuraci&oacute;n&nbsp;apropiada a tal finen su navegador. Si bien esta opci&oacute;n de bloqueo de Cookies en su navegador puede no permitirle el uso pleno de todas las funcionalidades del Website.</p>
			<p style="text-align: justify;">Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuraci&oacute;n de las opciones del navegador instalado en su ordenador:</p>
			<ul style="text-align: justify;">
			<li>Chrome</li>
			<li>Internet Explorer</li>
			<li>Microsoft Edge</li>
			<li>Firefox</li>
			<li>Safari</li>
			</ul>
			<p style="text-align: justify;">Si tiene dudas sobre esta pol&iacute;tica de cookies, puede contactar al Tel&eacute;fono: (507) 265-0428, (507) 265-3104 &nbsp;en info@3tech-panama.com</p>
        </div>
		<div class="modal-footer">
			<button class="btn btn-danger"  (click)="modal.dismiss('Cross click')">Cerrar</button>
			<button class="btn btn-success" (click)="aceptarCo()">Aceptar</button>
		</div> 
	</ng-template>