import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceIdentityService } from 'src/app/shared/ATTT/service/auth-service-identity.service';
declare var require
const Swal = require('sweetalert2');
@Component({
  selector: 'app-forget-pwd',
  templateUrl: './forget-pwd.component.html',
  styleUrls: ['./forget-pwd.component.scss']
})
export class ForgetPwdComponent implements OnInit {

  public token:any;
  public email:any;
  public passwordReset: FormGroup;
  constructor(private rutaActiva: ActivatedRoute,private ruta:Router,private fb: FormBuilder, public authService:AuthServiceIdentityService) { 
    
    this.token= rutaActiva.snapshot.params.id;
    this.email= rutaActiva.snapshot.params.email;
    if (this.token =='') {
      ruta.navigate(['/auth/login']);
    }
    this.passwordReset = fb.group({
      email: [this.email, [Validators.required,Validators.email]],
      password: ['', [Validators.required]],
      VerifiedPassword: ['', [Validators.required]],
      TokenPost: [this.token,[]],
    });
    
  }

  ngOnInit() { 

  }

  CambiarContrasena(){
    if (this.passwordReset.controls.password.value == this.passwordReset.controls.VerifiedPassword.value) {
      this.authService.CambiarContrasena(this.passwordReset.value);
    }else{
      this.success('warning','Validación','Las contraseña no son iguales')
    }
    
  }

  success(type:string,title:string,text:string) {
    Swal.fire(
      title,
      text,
      type
    );
  }

}
