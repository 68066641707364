import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeStatus'
})
export class ChangeStatusPipe implements PipeTransform {

  transform(value:number): string {
    if(value == 1){
      return 'No de Carga';
    }else if(value == 2){
      return 'De Carga';
    }else{
      return  '';
    }
  }

}
