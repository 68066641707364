export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAp-K7-hXsksE7Mzva91fFjU4eHYsDH9LU",
    authDomain: "ecarcheck-652c4.firebaseapp.com",
    projectId: "ecarcheck-652c4",
    storageBucket: "ecarcheck-652c4.appspot.com",
    messagingSenderId: "39922707819",
    appId: "1:39922707819:web:ca40a6ddef0d1341d8f02e"
  },
  Services: {
    // Auth: "https://microservicioidentity-revisados-dev.apps.revisados-attt.8ckj.p1.openshiftapps.com/api/v1/ServiceUser",
    // Taller: "https://tallermicroservice-revisados-dev.apps.revisados-attt.8ckj.p1.openshiftapps.com/api/v1",
    // Revisados: "https://serviciorevisados-revisados-dev.apps.revisados-attt.8ckj.p1.openshiftapps.com/api/v1/",
    // Maestras: "https://tmaestras-revisados-dev.apps.revisados-attt.8ckj.p1.openshiftapps.com/api/v1",
    // Verificacion: "https://dev.ecarcheck.net/Verificar/",
    // VerificacionPre: "https://dev.ecarcheck.net/VerificarPre/",
    // Dominio: "https://dev.ecarcheck.net/",
    // MyPayment: "https://apipayment-git-revisados-dev.apps.revisados-attt.8ckj.p1.openshiftapps.com/Api/",
    // ApiLog: "https://servicioslog-git-revisados-dev.apps.revisados-attt.8ckj.p1.openshiftapps.com/api/",
    // ApiMantenimiento: "https://serviciocupos-revisados-dev.apps.revisados-attt.8ckj.p1.openshiftapps.com",
    // ApiPreRevisados: "https://prerevisado-revisados-dev.apps.revisados-attt.8ckj.p1.openshiftapps.com/api/",
    // ACHService: "https://ach-revisados-dev.apps.revisados-attt.8ckj.p1.openshiftapps.com/api/",

    Auth: "https://microservicioidentity-revisados-pro.apps.revisados-attt.8ckj.p1.openshiftapps.com/api/v1/ServiceUser",
    Taller: "https://tallermicroservice-revisados-pro.apps.revisados-attt.8ckj.p1.openshiftapps.com/api/v1",
    Revisados: "https://serviciorevisados-revisados-pro.apps.revisados-attt.8ckj.p1.openshiftapps.com/api/v1/",
    Maestras: "https://tmaestras-revisados-pro.apps.revisados-attt.8ckj.p1.openshiftapps.com/api/v1",
    Verificacion: "https://ecarcheck.net/Verificar/",
    VerificacionPre: "https://ecarcheck.net/VerificarPre/",
    Dominio: "https://ecarcheck.net/",
    MyPayment: "https://apipayment-revisados-pro.apps.revisados-attt.8ckj.p1.openshiftapps.com/Api/",
    ApiLog: "https://servicioslog-revisados-pro.apps.revisados-attt.8ckj.p1.openshiftapps.com/api/",
    ApiMantenimiento: "https://serviciocupos-revisados-pro.apps.revisados-attt.8ckj.p1.openshiftapps.com",
    ApiPreRevisados: "https://prerevisado-revisados-pro.apps.revisados-attt.8ckj.p1.openshiftapps.com/api/",
    ACHService: "https://ach-revisados-pro.apps.revisados-attt.8ckj.p1.openshiftapps.com/api/"
  }


};
