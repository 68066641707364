import { Injectable } from '@angular/core';
import { ConfigServiceService } from '../config/config-service.service';
import { HttpClient } from '@angular/common/http';
import { ListadoLog, ListadoPagos, Registros, ReportePagos } from '../model/IReports';
import { ValidateBoletas } from '../model/XPayments';
import { ResponseACH } from '../model/IResponseACH';
declare var require
const Swal = require('sweetalert2');
@Injectable({
  providedIn: 'root'
})
export class ReportServicesService {
  public listadoLog:ListadoLog;
  public listadoLogReport:ListadoLog;
  public RegistroLog:Registros;
  public listadoPagos:ListadoPagos;
  public reportePagos: ReportePagos;
  public _ListadoACH: ResponseACH;
  public CountPage= [];
  public cargando:boolean=false;
  public buscando:boolean=false;
  public buscandoFiltro: boolean = false;
  public ValidarComprobantes: ValidateBoletas;
  constructor(public _config:ConfigServiceService, private _http:HttpClient) { }

  GetLogRegister(data:any){
    this.buscando=true;
    this.CountPage=[];
    return this._http.post(`${this._config.URL_SERVICE_LOG}GetLogAdmin`,data).subscribe({
      next:(res:ListadoLog)=>{
        this.listadoLog = res;

        for (let index = 0; index < this.listadoLog.totalPage; index++) {
          this.CountPage.push(index+1)
        }
      },
      error:(err)=>{
        this.success('warning','ECARCHECK',err)
        this.buscando=false;
      },
      complete:()=>{
        this.cargando=false;
        this.buscando=false;
      }
    })
  }

  GetLogRegisterExport(data:any){
    return this._http.post(`${this._config.URL_SERVICE_LOG}GetLogAdmin`,data);
  }
  GetLogPagos(data:any){
    this.buscando=true;
    this.buscandoFiltro=true;
    this.CountPage=[];
    return this._http.post(`${this._config.URL_SERVICE_PAYMENTS}GetReportAdmin`,data).subscribe({
      next:(res:any)=>{
        this.listadoPagos = res;
        
        for (let index = 0; index < this.listadoPagos.totalPage; index++) {
          this.CountPage.push(index+1)
        }
      },
      error:(err)=>{
        this.success('warning','ECARCHECK',err);
        this.buscandoFiltro=false;
        this.buscando=false;
      },
      complete:()=>{
        this.cargando=false;
        this.buscando=false;
        this.buscandoFiltro=false;
      }
    })
  }


    GetLogPagosTaller(data:any){
    this.buscando=true;
    this.buscandoFiltro=true;
    this.CountPage=[];
    return this._http.post(`${this._config.URL_SERVICE_PAYMENTS}GetReportTaller`,data).subscribe({
      next:(res:any)=>{
        this.listadoPagos = res;
        
        for (let index = 0; index < this.listadoPagos.totalPage; index++) {
          this.CountPage.push(index+1)
        }
      },
      error:(err)=>{
        this.success('warning','ECARCHECK',err);
        this.buscandoFiltro=false;
        this.buscando=false;
      },
      complete:()=>{
        this.cargando=false;
        this.buscando=false;
        this.buscandoFiltro=false;
      }
    })
  }


  GetLogPagosExcel(data:any){
    return this._http.post(`${this._config.URL_SERVICE_PAYMENTS}GetReportAdmin`,data);
  }

  GetDataDashboard(data:any){
    return this._http.post(`${this._config.URL_SERVICE_PAYMENTS}GetReportEcarcheckDashboard`,data);
  }

  GetDataDashboardRevisados(data:any){
    return this._http.post(`${this._config.URL_SERVICE_REVISADO}/GetResumenRevisados`,data);
  }

    GetDataDashboardPreRevisados(data:any){
    return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}GetResumenRevisados`,data);
  }



    GetLogPagosTallerExcel(data:any){
    return this._http.post(`${this._config.URL_SERVICE_PAYMENTS}GetReportTaller`,data);
  }

  validComprobantes(data:any){
    return this._http.post(`${this._config.URL_SERVICE_PAYMENTS}ValidarComprobante`,data);
  }

  GetACH(data: any) {
    this.InitACH();
    this.CountPage = [];
     return this._http.post(`${this._config.URL_SERVICE_ACH}GetSolicitudByTaller`, data)
     .subscribe({
      next:(res:ResponseACH)=>{
        this._ListadoACH = res;
        
        for (let index = 0; index < this._ListadoACH.totalPage; index++) {
          this.CountPage.push(index+1)
        }
      },
      error:(err)=>{
        this.success('warning','ECARCHECK',err);
        this.buscandoFiltro=false;
        this.buscando=false;
      },
      complete:()=>{
        this.cargando=false;
        this.buscando=false;
        this.buscandoFiltro=false;
      }
    })
  }

    GetACHAdmin(data: any) {
      this.InitACH();
    this.CountPage = [];
     return this._http.post(`${this._config.URL_SERVICE_ACH}GetAllSolicitudesByAdmin`, data)
     .subscribe({
      next:(res:ResponseACH)=>{
        this._ListadoACH = res;
        
        for (let index = 0; index < this._ListadoACH.totalPage; index++) {
          this.CountPage.push(index+1)
        }
      },
      error:(err)=>{
        this.success('warning','ECARCHECK',err);
        this.buscandoFiltro=false;
        this.buscando=false;
      },
      complete:()=>{
        this.cargando=false;
        this.buscando=false;
        this.buscandoFiltro=false;
      }
    })
  }


  InitACH() {
    this._ListadoACH = {
      totalRecord: 0,
      totalPage: 0,
      status: 0,
      message: '',
      data: [{
        solicitudId: 0,
        nroTransaccion: '',
        fechaTransaccion: new Date(),
        bancoEmisor:'',
        montoTransaccion:'',
        documento:'',
        tipoCupo:0,
        idTaller:0,
        estado:0,
        tallerName:'',
        codigoTaller:'',
        createdBy:'',
        createdDate: new Date(),
        fechaAprobacion: new Date(),
      }]
    }
  }
  
  InitLog(){
    this.listadoLog = {
      message:'',
      totalPage:0,
      totalRecord:0,
      status:0,
      data:[{
        idLog:        0,
      detalle:      '',
      ip:           '',
      origen:       '',
      createdBy:    '',
      typeRegister: '',
      createdById:  0,
      createdDate:  '',
      }]
    };

  }

  initPasarela(){
    this.listadoPagos = {
      message:'',
      totalPage:0,
      totalRecord:0,
      status:0,
      data:[
        {
          estado:0,
          idRegister:        0,
          createdBy:         '.............',
          ownerBy:           '.............',
          orderId:           '.............',
          amount:            '0.00',
          amountEmpreOne:    '0.00',
          amountEmpreTwo:    '0.00',
          idTrasaction:      '.............',
          typeBuy:           '.............',
          lotBuy:            0,
          respuestaServidor: '..........',
          amountBanco: '0.00',
          tipoPago:'............'
        }
      ],
      dataCompleta:[
        {
          estado:0,
          idRegister:        0,
          createdBy:         '.............',
          ownerBy:           '.............',
          orderId:           '.............',
          amount:            '0.00',
          amountEmpreOne:    '0.00',
          amountEmpreTwo:    '0.00',
          idTrasaction:      '.............',
          typeBuy:           '.............',
          lotBuy:            0,
          respuestaServidor: '..........',
          amountBanco: '0.00',
          tipoPago:'............'
        }
      ],
      dataDashBoard: [
        {
          idRegister:        0,
          createdBy:         '',
          ownerBy:           '',
          orderId:           '',
          amount:            '',
          amountEmpreOne:    '',
          amountEmpreTwo:    '',
          amountBanco:       '',
          idTrasaction:      '',
          typeBuy:           '',
          lotBuy:            0,
          tipoPago:          '',
          estado:            0,
          respuestaServidor: '',
          createdDate:       new Date()
        }
      ]
    };

  }

  initComprobantes() {
    this.ValidarComprobantes = {
      message:'',
      status:0,
      totalPage:0,
      totalRecord:0,
      data:'',
      gananciaEcarcheck:0,
      gananciaATTT:0,
      
    }
  }
  success(type:string,title:string,text:string) {
    Swal.fire(
      title,
      text,
      type
    );
  }

}
