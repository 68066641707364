<div class="container-fluid">
  <div class="authentication-main">
    <div class="row">
      <div class="col-md-12 p-0">

        <div class="text-center"> 
          <img src="/assets/images/logos/logo.png" alt="">
          <!-- <h6>INGRESE SUS CREDENCIALES</h6> -->
        </div>

        <div class="auth-innerright">
          <div class="reset-password-box">
            <div class="card mt-4 mb-0">
            
              <form class="theme-form" [formGroup]="passwordReset">
                <h6 class="f-14 mt-4 mb-3">Crear Nueva Contraseña</h6>

                <div class="form-group">
                  <label class="col-form-label">Correo Electrónico</label>
                  <input class="form-control" formControlName="email" type="email" placeholder="Correo Electrónico" readonly>
                </div>

                <div class="form-group">email
                  <label class="col-form-label">Nueva Contraseña</label>
                  <input class="form-control" formControlName="password" type="password" placeholder="Nueva Contraseña">
                  <div *ngIf="passwordReset.controls.password.touched && passwordReset.controls.password.errors?.required"
								class="text text-danger mt-1">
								Contraseña es Requerido
							</div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Repetir Contraseña</label>
                  <input class="form-control" formControlName="VerifiedPassword" type="password" placeholder="Repetir Contraseña">
                  <div *ngIf="passwordReset.controls.VerifiedPassword.touched && passwordReset.controls.VerifiedPassword.errors?.required"
								class="text text-danger mt-1">
								Contraseña es Requerido
							</div>
                </div>
                <div class="form-group row mb-2">
                  <div class="col-md-2">
                    <button class="btn btn-primary" (click)="CambiarContrasena()" [disabled]="!passwordReset.valid" type="submit">Cambiar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
