import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ConfigServiceService } from '../config/config-service.service';

@Injectable({
  providedIn: 'root'
})
export class NotifyFirebaseService {

  constructor(public db: AngularFirestore, private _config:ConfigServiceService) { }

  createNotificacion() {
     return this.db.collection('notificaciones').add({
       taller: [],
        message: 'Buenas tardes,El dia 12 de Agosto se estaran realizando tareas de mantenimiento a las 7:00pm.',
        all: 1,
        status: 1,
        style: '#326694',
        color: 'white',
        icon:'fa fa-solid fa-info'
    });
  }


  getNotificacionActiva() {
    return  this.db.collection('notificaciones',x=>x.where('status','==',1)).snapshotChanges();
  }
  
  getNotificacionActivaAdmin() {
    return  this.db.collection('notificaciones').snapshotChanges();
  }
  
  
}
