import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TallerServiceService } from 'src/app/shared/ATTT/service/taller-service.service';

@Component({
  selector: 'app-get-payments-clave',
  templateUrl: './get-payments-clave.component.html',
  styleUrls: ['./get-payments-clave.component.css']
})
export class GetPaymentsClaveComponent {
  public Token:any;
  constructor(private rutaActiva: ActivatedRoute,private ruta:Router, public _servicesTaller:TallerServiceService) { 
    
    this.Token= rutaActiva.snapshot.queryParams;
    _servicesTaller.ValidTClave(this.Token.session_id);
    
  }
}
