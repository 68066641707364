import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RevisadoServiceService } from 'src/app/shared/ATTT/service/revisado-service.service';
import { environment } from '../../../../../environments/environment';
import * as CryptoJS from 'crypto-js';  
declare var require
const Swal = require('sweetalert2');
@Component({
  selector: 'app-verify-documento',
  templateUrl: './verify-documento.component.html',
  styleUrls: ['./verify-documento.component.scss']
})
export class VerifyDocumentoComponent {
  public valor:string ='';
  public valido:boolean=false;
  public data:any;
  constructor(private rutaActiva: ActivatedRoute,private ruta:Router,public RevisadoS:RevisadoServiceService) { 
    
    this.valor= rutaActiva.snapshot.params.valor;
    if(this.valor == ''){
      ruta.navigate(['/auth/login']);
      localStorage.clear();
    }else{
      var cadena = this.replaceAll(this.valor,'~','/');
      var cadenaFinal = this.replaceAll(cadena,'_','+');
      let encrip = CryptoJS.AES.decrypt(cadenaFinal.trim(), 'Panama2019$').toString(CryptoJS.enc.Utf8); 
      let resultado = encrip.split('|');
      let dataResponse = {
        Placa:resultado[0],
        Token:resultado[1]
      }
      RevisadoS.GetValidationRevisado(dataResponse).subscribe({
        next:(res:any)=>{
          this.data=res;
          if(res.codeError==500){
            this.valido=false;
          this.success('warning','ECARCHECK',this.data.data);
          }else if(res.codeError==201){
            this.valido=false;
          this.success('success','ECARCHECK',this.data.data);
          }else{
            this.valido=true;
          this.success('success','ECARCHECK',this.data.data);
          }
          
        },
        error:(err)=>{
          this.success('warning','ECARCHECK','Intente nuevamente');
        },
        complete:()=>{
        }
      });
    }
  }

  escapeRegExp(string:string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  replaceAll(str:string, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  ngOnInit(): void {
  }

  success(type:string,title:string,text:string) {
    Swal.fire(
      title,
      text,
      type
    );
  }
}
