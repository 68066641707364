import { Component } from '@angular/core';

@Component({
  selector: 'app-politica-devolucion',
  templateUrl: './politica-devolucion.component.html',
  styleUrls: ['./politica-devolucion.component.css']
})
export class PoliticaDevolucionComponent {

}
