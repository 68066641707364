

<div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="text-center">
              <!-- <h5>INICIO DE SESIÓN</h5> -->
              <img src="/assets/images/logos/logo.png" alt="">
              <!-- <h6>INGRESE SUS CREDENCIALES</h6> -->
          </div>
                <p style="text-align: center;"><strong>POL&Iacute;TICAS DE DEVOLUCI&Oacute;N, CANCELACI&Oacute;N Y DE REEMBOLSO</strong></p>
                <p style="text-align: justify;">Estas Pol&iacute;ticas son v&aacute;lidas para el sitio web&nbsp;<a title="www.ecarcheck.net&nbsp;" href="http://www.ecarcheck.net" target="_blank" rel="noopener"><strong><u>www.ecarcheck.net</u></strong><strong>&nbsp;</strong></a></p>
                <ol style="text-align: justify;">
                <li><strong>DESCRIPCION</strong></li>
                </ol>
                <p style="text-align: justify;">El reembolso es la solicitud de un cliente por recibir el monto que ha pagado por un art&iacute;culo o servicio.</p>
                <ol style="text-align: justify;" start="2">
                <li><strong>MOTIVOS DEL&nbsp;REEMBOLSO</strong></li>
                </ol>
                <ul style="text-align: justify;">
                <li>Se ha cobrado un monto incorrecto al cliente, el reembolso aplica por la diferencia.</li>
                <li>Cuando compre el cliente y no se refleje en la cuenta de<strong><u>net</u></strong>dichas compras. El cliente tendr&aacute; un m&aacute;ximo de 24 horas para solicitar el reembolso.</li>
                <li>Debido a los diferentes escenarios que pueden haber en las compras en l&iacute;nea,&nbsp;todos&nbsp;aquellos casos no contemplados en estas pol&iacute;ticas se revisar&aacute;n y procesaran por&nbsp;<strong>3TECH LATIN AMERICA, CORP.,</strong><strong>&nbsp;</strong></li>
                </ul>
                <ol style="text-align: justify;" start="3">
                <li><strong>REQUISITOS</strong></li>
                </ol>
                <ul style="text-align: justify;">
                <li>Para solicitar un reembolso, se te pedir&aacute; la siguiente informaci&oacute;n, la cual puedes hacer llegar por Correo Electr&oacute;nico, chat o Telef&oacute;nicamente:</li>
                <li>Motivo por el cual se solicita el reembolso.</li>
                <li>Forma de pago con la que se adquiri&oacute; la compra.</li>
                <li>Confirmada la recepci&oacute;n de solicitud de reembolso se proceder&aacute; al proceso de revisi&oacute;n del mismo, la recepci&oacute;n de solicitud no implica la autorizaci&oacute;n del reembolso.</li>
                <li>Las solicitudes de reembolso se analizar&aacute;n y se dar&aacute; avis&oacute; al cliente tanto en casos de proceder como en casos de no procedencia de los reembolsos y los motivos.</li>
                <li><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TIEMPOS</strong></li>
                </ul>
                <p style="text-align: justify;">Los tiempos estimados para el procesamiento de un Reembolso una vez autorizado el mismo son:</p>
                <ul style="text-align: justify;">
                <li>Pago con TDC (Tarjeta de Cr&eacute;dito), TDB (Tarjeta de d&eacute;bito)&nbsp; tiempo de 10 a 15 d&iacute;as h&aacute;biles.</li>
                </ul>
        </div>
      </div>
    </div>
  </div>
