import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusBinarios'
})
export class StatusBinariosPipe implements PipeTransform {

  transform(value:number): string {
    if(value == 1){
      return 'Activo';
    }else if(value == 0){
      return 'Inactivo';
    }else{
      return  '';
    }
  }
}
