<div class="error-wrapper maintenance-bg">
  <div class="container">
    <ul class="maintenance-icons">
      <li><i class="fa fa-cog"></i></li>
      <li><i class="fa fa-cog"></i></li>
      <li><i class="fa fa-cog"></i></li>
    </ul>
    <div class="maintenance-heading">
    </div>
    <!-- <h4 class="sub-content">Nuestro sitio se encuentra actualmente en mantenimiento. Volveremos en breve.
      Gracias por tu paciencia</h4> -->
    <!-- <div><a class="btn btn-info-gradien btn-lg text-light" [routerLink]="'/auth/login'">Login</a>
    </div> -->
  </div>
</div>