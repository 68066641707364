import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ConfigServiceService } from '../config/config-service.service';
import { Router } from '@angular/router';
import { GenericoCatalogo } from '../model/Catalogo';
import { RequestPhotoPreRevisado, RequestPreRevisados } from '../model/RequestPreRevisados';
import { DataPreResponse } from '../model/ResponseListPre';
import { DatosResponsePre } from '../model/FilePreRevisado';
import { ListadoPreRevisado } from '../model/ListadoReportePre';
import { ResponsePropietario } from '../model/ResponsePropietarioPre';
import { DocumentosPreRevisados, ImagenPreRevisado } from '../model/ImagenPreRevisado';

@Injectable({
  providedIn: 'root'
})
export class ServicePreRevisadosService {
  public _Catalogo: GenericoCatalogo;
  public _CatalogoModelo: GenericoCatalogo;
  public _CatalogoAseguradora: GenericoCatalogo;
  public _CatalogoColores: GenericoCatalogo;
  public _CatalogosProvincia: GenericoCatalogo;
  public _CatalogosDistrito: GenericoCatalogo;
  public _CatalogosCorregimiento: GenericoCatalogo;
  public _CatalogosPais: GenericoCatalogo;
  public _CatalogoTipoPlaca: GenericoCatalogo;
  public _CatalogoTipoVehiculo: GenericoCatalogo;
  public _CatalogoMarcaMotor: GenericoCatalogo;
  public _RequestPre: RequestPreRevisados;
  public _CatalogosNacionalidad: GenericoCatalogo;
  public _CatalogosPaisP: GenericoCatalogo;
  public _ListadoPre: DataPreResponse;
  public _Info: DatosResponsePre;
  public _CatalogosProvincioP: GenericoCatalogo;
  public _CatalogosDistritoP: GenericoCatalogo;
  public _CatalogosCorregimientoP: GenericoCatalogo;
  public cantidadHoy: number;
  public cantidadTotal: number;
  public _ListadoPreReporte: ListadoPreRevisado;
  public _ListadoImagePre:ImagenPreRevisado
  public CountPage=[];
  public buscandoDatos: boolean = false;
  public cargando: boolean = false;
  public _PropietarioR: ResponsePropietario;
  public _DocumentosPre: DocumentosPreRevisados;
  public _ACHAutorizacion: boolean = false;
 constructor(private _http:HttpClient,
    private _config:ConfigServiceService,
    public ngZone: NgZone,
    public NavCtrl:Router
 ) {
   this.initall();
 }
  
  initall() {
    this.initModeloC();
   this.initCatalogo();
   this.initCatalogoAseguradora();
   this.intiCatalogoColores();
   this.initPreRevisado();
   this.initCatalogoTipoPlaca();
   this.initCatalogoDistrito();
   this.initCatalogoCorregimiento();
    this.initCatalogoTipoVehiculo();
    this.initCatalogoMotor();
  }
  GetMarca(marca: string) {
      let DatosBusuqueda={
        Busqueda: marca,
        CodigoTaller: '',
        Token:this._config.Token
      }
      return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}ApiGetMarcas`,DatosBusuqueda);
  }
  
    GetModelos(mdelo: string) {
      let DatosBusuqueda={
        Busqueda: mdelo,
        CodigoTaller: '',
        Token:this._config.Token
      }
      return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}ApiGetModelos`,DatosBusuqueda);
    }
  
  
  GetAseguradora(mdelo: string) {
      let DatosBusuqueda={
        Busqueda: mdelo,
        CodigoTaller: '',
        Token:this._config.Token
      }
      return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}ApiGetAseguradora`,DatosBusuqueda);
    }

  GetColores(colores: string) {
      let DatosBusuqueda={
        Busqueda: colores,
        CodigoTaller: '',
        Token:this._config.Token
      }
      return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}ApiGetColores`,DatosBusuqueda);
  }

  GetTipoVehiculoPlaca() {
    let DatosBusuqueda = {
        Busqueda: '...',
        CodigoTaller: '',
        Token:this._config.Token
      }
      return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}ApiGetTipoPlaca`,DatosBusuqueda);
  }

  GetNacionalidad() {
     let DatosBusuqueda = {
        Busqueda: '...',
        CodigoTaller: '',
        Token:this._config.Token
      }
      return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}ApiGetPais`,DatosBusuqueda);
  }

  GetProvincia() {
      let DatosBusuqueda = {
        Busqueda: '...',
        CodigoTaller: '',
        Token:this._config.Token
      }
      return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}ApiGetProvincia`,DatosBusuqueda);
  }


    GetUbicaciones(valor:string) {
      let DatosBusuqueda={
        Busqueda: valor,
        CodigoTaller: '',
        Token:this._config.Token
      }
      return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}ApiGetUbicaciones`,DatosBusuqueda);
    }
  
  GetTipoVehiculo(valor: string) {
      let DatosBusuqueda={
        Busqueda: valor,
        CodigoTaller: this._config.TallerActivo.codigo,
        Token:this._config.Token
      }
      return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}ApiGetVehiculoTaller`,DatosBusuqueda);
  }

    validarDatos(valor: string) {
      let DatosBusuqueda={
        Busqueda: valor,
        CodigoTaller: '',
        Token:this._config.Token
      }
      return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}VerificarDatos`,DatosBusuqueda);
  }

  guardarPreRevisado(data: RequestPreRevisados) {
      return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}SaveTemPreRevisado`,data);
  }

SavePhotoPre(params:RequestPhotoPreRevisado) {
    
    return this._http.post(`${this._config.URL_SERVICE_PREREVISADO_UPLOAD}UploadFoto`,params);
  }

  GetIdRevisadoPhoto(dataPlacaGetImage:any) {
  return this._http.post(`${this._config.URL_SERVICE_PREREVISADO_UPLOAD}GetImagePreId`,dataPlacaGetImage);
  }

  
UploadFiles(Data:any){
  return this._http.post(`${this._config.URL_SERVICE_PREREVISADO_UPLOAD}UploadFile`, Data);
  }
  
  GetDocumentos(Data:any){
    return this._http.post(`${this._config.URL_SERVICE_PREREVISADO_UPLOAD}GetPDF`, Data);
  }

  SendPre(Data:any){
    return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}SendPreASE`, Data);
  }

  getCountPre(tipo:number){
  this._config.getUserLocal();

  if (this._config.TallerActivo == null) {
    this.NavCtrl.navigate(['/Panel-control']);
    return;
  }

  let data = {
    tallerId: this._config.TallerActivo.id,
    token: this._config.Token,
    TipoRevisado: 'PRE',
    TipoDate:tipo,
    CreatedBy: this._config.TallerUserActivo.usuarioSistema,
    Anio: '',
    DateInit: '',
    DateEnd: '',
    Valor:''
  }

    return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}ContarCantidadPre`, data).
      subscribe({
        next: (res:any) => {
          
          if (tipo == 1) {
            this.cantidadTotal = res.totalCount;
          }

          if (tipo == 2) {
            this.cantidadHoy = res.totalCount;

          } 
        },
        error: (err: any) => {
          console.log(err);
          
        }, complete: () => {
        }
                })
  }


  GetRevisados(tipo: number) {
    this.initPreListado();
  this._config.getUserLocal();

  if (this._config.TallerActivo == null) {
    this.NavCtrl.navigate(['/Panel-control']);
    return;
  }

  let data = {
    tallerId: this._config.TallerActivo.id,
    token: this._config.Token,
    TipoRevisado: 'PRE',
    TipoDate:tipo,
    CreatedBy: this._config.TallerUserActivo.usuarioSistema,
    Anio: '',
    DateInit: '',
    DateEnd: '',
    Valor:''
  }

    return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}GetAllPreRevisados`, data).
      subscribe({
        next: (res:DataPreResponse) => {
          this._ListadoPre = res;
        },
        error: (err: any) => {
          console.log(err);
        }, complete: () => {
        }
    })
  }

  getRevisadoPrint(requestRevisadoPrint:any){
  return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}ShowPDFPreRevisado`,requestRevisadoPrint);
  }

  DetailtRev(requestRevisadoPrint:any){
  return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}ShowDetalleEditar`,requestRevisadoPrint);
  }


  GetBS64(data:any) {
  return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}getImagePreBS64`,data);
  }

  DeleteSolicitud(data:any) {
  return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}EliminarSolicitud`,data);
  }

  GetValidationRevisado(data:any) {
    return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}VerificarPreRevisado`,data);
  }

  AutorizacionTallerPre(data:any) {
  return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}AutorizacionTallerPre`,data);
  }

  AuthorizarTaller(data:any) {
  return this._http.post(`${this._config.URL_SERVICE_ACH}AuthorizarTaller`,data);
  }

   GetAuthACH(data:any) {
     return this._http.post(`${this._config.URL_SERVICE_ACH}GetAuthACH`, data).subscribe((res:any) => {
    
       if (res.statusCode == 200) {
         this._ACHAutorizacion = true;
       } else {
         this._ACHAutorizacion = false;
       }
  })
  }



  GetAutorizacion(data:any) {
  return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}GetAutorizacion`,data);
  }

  ReimprimirPreRevisado(data:any) {
  return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}ReimprimirPreRevisado`,data);
  }

 AnularRevisados(data:any) {
  return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}AnularRevisados`,data);
  }

  getPreRevisados(data: any) {
    this.cargando = true;
      this.CountPage=[];
    this.initPreRevisadoReporte();
    return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}AdminPreRevisadoListado`, data).subscribe({
      next: (res:ListadoPreRevisado) => { 
         this._ListadoPreReporte=res;
        for (let index = 0; index < res.totalPage; index++) {
          this.CountPage.push(index + 1)
        }

      },
      error: (err: any) => {
        this.cargando = false;
      },
      complete: () => {
        this.cargando = false;
      }
  })
  }

  getPropietario(data: any) {
    this.InitPropierarioPre();
  return this._http.post(`${this._config.URL_SERVICE_PREREVISADO}GetPropietarioPre`,data);
  }
  InitDocumentosPre() {
    this._DocumentosPre = {
      aleatorio: '',
      codeError:0,
      message:'',
      idRevisados:'',
      tipoRevisado:'',
      imagen: '',
      listado: [
        {
          uploadId:0,
          idPreRevisado:'',
          anio:'',
          latitud:'',
          longitud:'',
          tallerId:'',
          tallerName:'',
          nombreArchivo:'',
          file:'',
          createdAt:new Date(),
          updatedAt:new Date(),
          createdby:'',
        }
      ]
    }
  }

  InitPropierarioPre() {
    this._PropietarioR = {
      propietarioId:0,
      codigoTipoPersona:'',
      textoTipoPersona:'',
      codigoTipoDocumento:'',
      textoTipoDocumento:'',
      nroDocumento:'',
      primerNombreRazonSocial:'',
      segundoNombre:'',
      apellidoPaterno:'',
      apellidoMaterno:'',
      apellidoCasado:'',
      dominicio:'',
      codigoProvincia:'',
      textoProvincia:'',
      codigoDistrito:'',
      textoDistrito:'',
      codigoCorregimiento:'',
      textoCorregimineto:'',
      fechaNacimientoCostitucion:'',
      codigoPais:'',
      textoPais:'',
      codigoNacionalidad:'',
      textoNacionalidad:'',
      telefonoPersonal:'',
      telefonoLaboral:'',
      lugarTrabajo:'',
      correoElectronico:'',
      codigoSexo:'',
      textoSexo:'',
      codigoTipoPersonaRepresentante:'',
      textoTipoPersonaRepresentante:'',
      nroDocRepresentante:'',
      nombreRepresentante:'',
      createdAt:new Date(),
      updatedAt:new Date(),
      createdby:'',
    }
  }
  initImagenPre() {
    this._ListadoImagePre = {
      codeError: 0,
      message: '',
      listado: [
        {
          imagenRevisadoId:0,
          createdBy:'',
          createdDate:new Date(),
          updateDate:new Date(),
          tipo:'',
          idPreRevisado:'',
          image:'',
          anno:0
        }
      ]
    }
  }


  initPreRevisadoReporte() {
    this._ListadoPreReporte = {
      cantidad: 0,
      codeError: 0,
      message: '',
      totalPage: 0,
      totalRecord:0,
      dataPre: [
        {
          idPreRevisado:0,
          codigoTaller:'',
          tallerId:0,
          nombreTaller:'',
          anioPreRevisado:0,
          tipoPreRevisado:'',
          tipoUso:'',
          estado:'',
          responsable:'',
          fechaRevisado:new Date(),
          revId:'',
          enviado:0,
          imprimir:0,
          valido:0,
        }
      ]
    }
  }

  initPreListado() {
    this._ListadoPre = {
      cantidad: 0,
      codeError: 0,
      message: '',
      dataPre: [
        {
          idPreRevisado:0,
          codigoTaller:'9999',
          tallerId:0,
          nombreTaller:'........',
          anioPreRevisado:0,
          tipoPreRevisado:'........',
          tipoUso:'........',
          estado:'........',
          responsable:'........',
          fechaRevisado: new Date(),
          revId:'........',
          enviado:0,
          imprimir:0,
          valido:0,
        }
      ]
    }
  }
  
  initInfoPDF() {
    this._Info = {
      codeError: 0,
      message: '',
      primerNombreRazonSocial: '',
      nroDocumento: '',
      textoNacionalidad: '',
      fechaNacimientoCostitucion: '',
      textoSexo:'',
      revisados: {
        preRevisadoId:0,
        anioPreRevisado:0,
        tipoFormulario:'',
        tipoPreRevisado:'',
        tipoUso:'',
        cupoCertificacion:'',
        estadoRevisado:'',
        metroBus:0,
        observacionGeneral:'',
        propietarioId:0,
        tallerId:0,
        codigoTaller:'',
        tallerName:'',
        nroLiquidacion:'',
        fechaLiquidacion:new Date(),
        codigoTipoPlaca:'',
        textoTipoPlaca:'',
        codigoTipoAuto:'',
        textoTipoAuto:'',
        codigoMarcaVehiculo:'',
        textoMarcaVehiculo:'',
        codigoModeloVehiculo:'',
        textoModeloVehiculo:'',
        anioVehiculo:'',
        nroVin:'',
        nroChasis:'',
        nroMotor:'',
        codigoColorVehiculo:'',
        textoColorVehiculo:'',
        codigoTipoCapacidad:'',
        textoTipoCapacidad:'',
        capacidad:'',
        carga:'',
        codigoMarcaMotor:'',
        textoMarcaMotor:'',
        codigoPosicionMotor:'',
        textoPosicionMotor:'',
        codigoUbicacionMotor:'',
        textoUbicacionMotor:'',
        codigoTraccionMotor:'',
        textoTraccionMotor:'',
        cilindrada:'',
        nroCilindros:'',
        codigoTipoMasterCilindro:'',
        textoTipoMasterCilindro:'',
        codigoTipoCombustible:'',
        textoTipoCombustible:'',
        codigoTipoFrenos:'',
        textoTipoFrenos:'',
        codigoTipoFrenosDelanteros:'',
        textoTipoFrenosDelanteros:'',
        codigoTipoFrenosTraseros:'',
        textoTipoFrenosTraseros:'',
        nroPuertas:'',
        kilometraje:'',
        aireAcondicionado:'',
        codigoTipoTimon:'',
        textoTipoTimon:'',
        codigoTipoTransnision:'',
        textoTipoTransnision:'',
        nroVelocidades:'',
        codigoTipoSuspension:'',
        textoTipoSuspension:'',
        codigoTipoDiferencial:'',
        textoTipoDiferencial:'',
        nroAgujeros:'',
        nroRin:'',
        nroLlantas:'',
        codigoPaisProcedencia:'',
        textoPaisProcedencia:'',
        valorVehiculo:'',
        nroPlacaForanea:'',
        codigoAseguradora:'',
        textoAseguradora:'',
        nroPolizaSeguro:'',
        observaciones:'',
        codigoProvincia:'',
        textoProvincia:'',
        codigoDistrito:'',
        textoDistrito:'',
        codigoCorregimiento:'',
        textoCorregimineto:'',
        revId:'',
        imprimir:0,
        enviado:0,
        validado:0,
        createdAt:new Date(),
        updatedAt:new Date(),
        createdby:'',
      }
    }
  }
  initCatalogo() {
    this._Catalogo = {
      detalle: '',
      respuesta: 0,
      resultado: [
        {
          codigo: 0,
          descripcion:''
        }
      ]
    }
  }
    initCatalogoMotor() {
    this._CatalogoMarcaMotor = {
      detalle: '',
      respuesta: 0,
      resultado: [
        {
          codigo: 0,
          descripcion:''
        }
      ]
    }
  }

  initPreRevisado() {
    this._RequestPre = {
      DatosGenerales: {
          TipodeFormulario:'',
          NroFormulario3T:'',
          RevFrvEmision:'',
          TipoRevisado:'',
          TipoUso:'',
          CertDeOperacion:'',
          EstadoRevisado:'APROBADO',
          TallerAutorizado:'',
          TallerUsuario:'',
          ImgFrontal:'',
          ImgPosterior:'',
          ImgLatDerecha:'',
          ImgLatIzquierda:'',
          MetroBus:'0',
          Observacion:'',
      },
      DatosVehiculo: {
        NumLiquidacion:'',
        FechaLiquidacion:'',
        TipoPlaca:'',
        TipoVehiculoAut:'',
        Marca:'',
        Modelo:'',
        anio:'',
        vin:'',
        Motor:'',
        Chasis:'',
        Color:'',
        TipoCapacidad:'',
        Capacidad:'',
        Carga:'',
        MarcaMotor:'',
        VprPosicionMotor:'',
        VprUbicacionMotor:'',
        VprTraccionMotor:'',
        VprCilindrada:'',
        vprNroCilindros:'',
        VprTipoMasterCilindro:'',
        VprTipoCombustible:'',
        VprTipoFrenos:'',
        VprTipoFrenosDelanteros:'',
        VprTipoFrenosTraseros:'',
        VprNroPuertas:'',
        VprKilometraje:'',
        VprAireAcondicionado:'',
        VprTipoTimon:'',
        VprTipoTransmision:'',
        VprNroVelocidades:'',
        VprTipoSuspension:'',
        VprTipoDiferencial:'',
        NumAgujeros:'',
        NumRin:'',
        NumLlantas:'',
        PaisProcede:'',
        VprValorVehiculo:'',
        NumPlacaForanea:'',
        Aseguradora:'',
        NumPolizaSeguro:'',
        VprObservaciones:'',
        Provincia:'',
        Distrito:'',
        Corregimiento:'',
      },
      DatosPropietario: {
        TipoPersona:'',
        TipoDocumento:'',
        NumDocumento:'',
        PdrPrimerNombreRazonSocial:'',
        PdrSegundosNombres:'',
        PdrApellidoPaterno:'',
        PdrApellidoMaterno:'',
        PdrApellidoCasada:'',
        PdrDomicilio:'',
        Provincia:'',
        Distrito:'',
        Corregimiento:'',
        FechaNacimientoConstitucion:'',
        Pais:'',
        Nacionalidad:'',
        PdrTelefonoPersonal:'',
        PdrTelefonoLaboral:'',
        PdrLugarTrabajo:'',
        PdrCorreoElectronico:'',
        TipoDocRepresentante:'',
        NumDocRepresentante:'',
        PdrNombreReplegal: '',
        PdrSexo:''
      }
      ,
      DatosResponsable: {
        TallerId:0,
        CreatedBy:'',
        CodigoTaller:'',
        Token:'',
        CodigoGenerado:'',
      }
      }
  }
  

    initModeloC() {
    this._CatalogoModelo = {
      detalle: '',
      respuesta: 0,
      resultado: [
        {
          codigo: 0,
          descripcion:''
        }
      ]
    }
    }
  
      initCatalogoAseguradora() {
        this._CatalogoAseguradora = {
          detalle: '',
          respuesta: 0,
          resultado: [
            {
              codigo: 0,
              descripcion:''
            }
          ]
        }
      }
  
      intiCatalogoColores() {
        this._CatalogoColores = {
          detalle: '',
          respuesta: 0,
          resultado: [
            {
              codigo: 0,
              descripcion:''
            }
          ]
        }
      }
    initCatalogoTipoPlaca() {
          this._CatalogoTipoPlaca = {
            detalle: '',
            respuesta: 0,
            resultado: [
              {
                codigo: 0,
                descripcion:''
              }
            ]
          }
    }
    
    initCatalogoDistrito() {
          this._CatalogosDistrito = {
            detalle: '',
            respuesta: 0,
            resultado: [
              {
                codigo: 0,
                descripcion:''
              }
            ]
          }
    }
  
      initCatalogoCorregimiento() {
          this._CatalogosCorregimiento = {
            detalle: '',
            respuesta: 0,
            resultado: [
              {
                codigo: 0,
                descripcion:''
              }
            ]
          }
      }
  
      initCatalogoTipoVehiculo() {
          this._CatalogoTipoVehiculo = {
            detalle: '',
            respuesta: 0,
            resultado: [
              {
                codigo: 0,
                descripcion:''
              }
            ]
          }
      }
  
}




