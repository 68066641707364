import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, config, Observer } from 'rxjs';
import { ConfigServiceService } from '../config/config-service.service';
import { Cuenta, ResponseRevisado, CantidadRevisados, RequestCupos, ResponsePlacas, ResponseDocumentos, ResPre, ResponseGenerico } from '../model/Revisado';
import { RespuestaXRevisadosTalleres, revisadoResponse } from '../model/ResponseRevisados';
import { ListadoRevisado, ListadoRevisadoMap, UploadFiles } from '../model/IRevisadoAdmin';
import { Router } from '@angular/router';
import { RequestPhoto } from '../model/RequestTallerR';
import { ListadoRevisadosReporte } from '../model/ReportRevisados';
import { OneRevisado } from '../model/oneRevisado';
declare var require
const Swal = require('sweetalert2');
@Injectable({
  providedIn: 'root'
})
export class RevisadoServiceService {
  public UploadFile:boolean=false;
  public cargando: boolean = true;
  public buscando: boolean = false;
  public buscandoFiltro: boolean = false;
  public placa_encontrada: boolean = false;
  public mostrarCuadro: boolean = false;
  public showReset: boolean = false;
  public Listado: revisadoResponse;
  public ListadoXTalleres:RespuestaXRevisadosTalleres;
  public data:ResponseRevisado;
  public conductor:Cuenta;
  public Cupos:RequestCupos;
  public placa:string='';
  public Selectanio:number=0;
  public guardandoRevisado = false;
  public anioRevisado = 2000;
  public anios=[];
  public CountPage=[];
  public _ListadoRevisado:ListadoRevisadosReporte;
  public _ListadoExcel:ListadoRevisadosReporte;
  public ListadoArchivo:ResponseDocumentos;
  public RespuestPre:ResPre;
  public TotalRevisado:number =0;
  public TotalRevisadoHoy:number =0;
  public  DocumentosRV:string='';
  public  DocumentosHA:string='';
  public  DocumentosPS:string='';
  public  GuardarDocumentos:boolean=false;
  public PrerevisadoSave:boolean=false;
  public ErroresArray: Array<any> = [];
  public _detalleOneRevisado: OneRevisado;
  constructor(private _http:HttpClient,
    private _config:ConfigServiceService,
    public ngZone: NgZone,
    public NavCtrl:Router
    ) {
      this.initInterface();
    }
     buscarPlaca(usuario:any,tipo:any){
      this.buscando=true;
      return this._http.post(`${this._config.URL_SERVICE_REVISADO}/GetRevisado`,usuario);
   }

   buscarCondutor(usuario:any){
    let data = {
      tipoDocuento : this.data.tipoDocumentoPropietario,
      valorDocumento:this.data.nroDocumentoPropietario,
      Ip:usuario.Ip,
      Platform:usuario.Platform,
      CreatedByName:usuario.CreatedByName,
      CreatedById:usuario.CreatedById,
      Token:usuario.Token,
      Rol:usuario.Rol
    };
    return this._http.post(`${this._config.URL_SERVICE_REVISADO}/GetConductor`,data)
                        .subscribe({
                          next:(res:Cuenta)=>{
                              this.ngZone.run(()=>{
                                this.conductor=res;
                                if(res.codeError ==200){
                                    if (this.data.tipoDocumentoPropietario == 'RUC') {
                                      this.conductor.tipoPersona='PERSONA JURIDICA';
                                    } else if (this.data.tipoDocumentoPropietario == 'CIP') {
                                      this.conductor.tipoPersona = 'PERSONA NATURAL';
                                    }
                                   this.GetCupoPlaca(usuario);
                                }else{
                                  // this.GetCupoPlaca(usuario);
                                  if(res.codeError == 501){
                                    let json = JSON.parse(res.message);
                                    this.buscando=false;
    
                                    this.success('warning','Validación',json.Placa[0]);
    
                                  }else{
                                    this.GetCupoPlaca(usuario);
                                      // this.success('warning','Intente Nuevamente.',res.message);
                                      this.placa_encontrada = false;
                                      this.mostrarCuadro=false;
                                      this.buscando=false;
                                  }
                                }
                              });
                          },
                          error:(err)=> {
                            
                            this.buscando=false;
                            this.success('warning','ECARCHECK','Intente Nuevamente, No se pudo buscar el propietario del Vehículo.');
                            this.placa_encontrada = false;
                            this.mostrarCuadro=false;
                          },
                          complete:()=> {
                          },
                        });
    }

    // GetCupGetCupoPlacaoPlaca
    GetCupoPlaca(usuario:any){
     let data = {
       tipoDocuento : 'Placa',
       valorDocumento:usuario.Placa,
       Ip:usuario.Ip,
       Platform:usuario.Platform,
       CreatedByName:usuario.CreatedByName,
       CreatedById:usuario.CreatedById,
       Token:usuario.Token,
       Rol:usuario.Rol
     };
     return this._http.post(`${this._config.URL_SERVICE_REVISADO}/GetCupoPlaca`,data)
                         .subscribe({
                           next:(res:RequestCupos)=>{
                                this.Cupos=res;
                                 if(res.codeError ==200){
                                     this.placa_encontrada = true;
                                     this.mostrarCuadro = true;
                                     this.init();

                                      if(this.Cupos.detalleRespuesta== 'OK'){
                                        this.data.tipoFormularios='TRANSPORTE';
                                        this.data.tipoUso = 'TRANSPORTE';
                                        this.data.cupo = this.Cupos.cupo;
                                      }

                                      if(this.Cupos.detalleRespuesta != 'OK'){
                                        this.data.tipoFormularios='PARTICULAR';
                                      }
                                      
                                     this.success('success','Éxito',this.data.message);
                                     this.buscando=false;
                                 }else{
                                  this.buscando=false;
                                   if(res.codeError == 501){
                                     let json = JSON.parse(this.data.message);
     
                                     this.success('warning','Validación',json.Placa[0]);
     
                                   }else{
                                    this.buscando=false;
                                       this.success('warning','Intente Nuevamente.',this.data.message);
                                       this.placa_encontrada = false;
                                       this.mostrarCuadro=false;
                                   }
                                 }
                           },
                           error:(err)=> {
                             
                             this.buscando=false;
                             this.success('warning','ECARCHECK','Intente Nuevamente, No se pudo buscar el Cupo asignado al Vehículo.');
                             this.placa_encontrada = false;
                             this.mostrarCuadro=false;
                           },
                           complete:()=> {
                            
                           },
                         });
     }

  // CZ1633
  SaveRevisados(data){
    
    return this._http.post(`${this._config.URL_SERVICE_REVISADO}/Saverevisado`,data)
                        .subscribe(
                          {
                            next:(res:any)=> {
                                this.ngZone.run(()=>{
                                  if(res.codeError ==200){
                                    this.initInterface();
                                    this.placa_encontrada =false;
                                    this.placa='';
                                    this.success('success','Éxito',res.message);
                                    this.guardandoRevisado=false;
                                    if(data.RequestRevisadoLlave.tipoRevisado == 'REVISADO'){
                                      this.NavCtrl.navigate(['/Revisados/revisado']);
                                    }else{
                                      this.NavCtrl.navigate(['/Revisados/pre-revisado']);
                                    }
                                  }else if(res.codeError == 501){
                                    this.guardandoRevisado=false; 
                                    this.ErroresArray=[];
                                      Object.values(JSON.parse(res.message)).forEach(x=>{
                                        this.ErroresArray.push(x);
                                      })

                                      this.success('warning','Validación','No se pudo guardar el revisado vehicular, existen datos incompletos, verificar en la parte de abajo las validaciones, si sigue igual después de varios intentos comuníquese con soporte Ecarcheck.');
                                    }else if(res.codeError == 503){
                                      this.guardandoRevisado=false;
                                        this.success('warning','Validación',res.message);
                                      }else{
                                        this.guardandoRevisado=false;
                                      this.success('warning','Validación',res.message);
                                    }
                                });
                              },
                            error:(err)=> {
                              
                              this.success('warning','ECARCHECK','Intente Guardar Nuevamente.');
                              this.guardandoRevisado=false;
                            },
                            complete:()=> {
                            },
                          }
                        );
  }

  SavePreRevisados(data){
    return this._http.post(`${this._config.URL_SERVICE_REVISADO}/SavePreRevisados`,data);
  }


  SavePhoto(params:RequestPhoto) {
    
    return this._http.post(`${this._config.URL_SERVICE_PHOTO}/UploadFoto`,params);
   }


GetRevisados(TipoRevisado:any,tipo:number){
  this._config.getUserLocal();
  if (this._config.TallerActivo == null) {
    this.NavCtrl.navigate(['/Panel-control']);
    return;
  }
  let data = {
    tallerId: this._config.TallerActivo.id,
    token: this._config.Token,
    TipoRevisado: TipoRevisado,
    TipoDate:tipo,
    CreatedBy:this._config.TallerUserActivo.usuarioSistema
  }

  return this._http.post(`${this._config.URL_SERVICE_REVISADO}/GetRevisados`,data)
                      .subscribe(
                        {
                          next:(res:RespuestaXRevisadosTalleres)=> {
                              this.ngZone.run(()=>{
                                if(res.codeError ==200){
                                  this.ListadoXTalleres=res;
                                }else{
                                  if(res.codeError == 501){
                                    let json = JSON.parse(this.data.message);
      
                                    this.success('error','Validación',this.Listado.message);
                                  }else{
                                    this.success('error','Validación',this.Listado.message);
                                  }
                                }
                              });
                          },
                          error:(err)=> {
                            this.success('warning','ECARCHECK','No se pudo cargar correctamente.');
                            this.guardandoRevisado=false;
                          },
                          complete:()=> {
                            this.GetRevisadosCount(TipoRevisado,1);
                            this.GetRevisadosCount(TipoRevisado,2);
                          },
                        }
                      );
  }
  
GetRevisadosCount(TipoRevisado:any,tipo:number){
  this._config.getUserLocal();

  if (this._config.TallerActivo == null) {
    this.NavCtrl.navigate(['/Panel-control']);
    return;
  }

  let data = {
    tallerId: this._config.TallerActivo.id,
    token: this._config.Token,
    TipoRevisado: TipoRevisado,
    TipoDate:tipo,
    CreatedBy:this._config.TallerUserActivo.usuarioSistema
  }

  return this._http.post(`${this._config.URL_SERVICE_REVISADO}/GetCountRevisados`,data)
                      .subscribe(
                        {
                          next:(res:CantidadRevisados)=> {
                              this.ngZone.run(()=>{
                                if(res.codeError ==200){
                                  if(tipo ==1){
                                     this.TotalRevisado = res.totalCount;
                                  }
                                  if(tipo ==2){
                                    this.TotalRevisadoHoy = res.totalCount;
                                  }
                                }else{
                                  if(res.codeError == 501){
                                      this.success('warning','ECARCHECK','No se pudo cargar correctamente.');
                                  }else{
                                      this.success('warning','ECARCHECK','No se pudo cargar correctamente.');
                                  }
                                }
                              });
                          },
                          error:(err)=> {
                            this.success('warning','ECARCHECK','No se pudo cargar correctamente.');
                            this.guardandoRevisado=false;
                          },
                          complete:()=> {
                          },
                        }
                      );
}


ExistePlaca(usuario:any){
  return this._http.post(`${this._config.URL_SERVICE_REVISADO}/GetRevisado`,usuario);
}
GetPlacaPhoto(dataPlacaGetImage:any) {
  return this._http.post(`${this._config.URL_SERVICE_PHOTO}/GetImages`,dataPlacaGetImage);
 }

 GetBS64(data:any) {
  return this._http.post(`${this._config.URL_SERVICE_REVISADO}/GetImageBS64`,data);
 }

//  CZ1637
 DinamicoAnio(){
  this.anios=[];
  
  let anio = new Date().getFullYear();
    this.anios.push(anio);
    this.anios.push(anio-1);
}

ImprimirRevisado(tallerId:number,numeroRevisado:number,token:string){
  return this._http.get(`${this._config.URL_SERVICE_REVISADO}/DescargarPDF?tallerId=${tallerId}&numeroRevisado=${numeroRevisado}&token=${token}`,{ responseType: 'blob' });
}

getRevisadoPrint(requestRevisadoPrint:any){
  return this._http.post(`${this._config.URL_SERVICE_REVISADO}/GetDataPdf`,requestRevisadoPrint);
}

GetValidationRevisado(RValidacion:any){
  return this._http.post(`${this._config.URL_SERVICE_REVISADO}/GetValidationRevisado`,RValidacion);
}
async GetIp4(){
  return await this._http.get('https://ipv4.icanhazip.com').subscribe(res=>res);
}

GetRevisadoAdmin(Data:any){
  this.cargando=true;
  this.CountPage=[];
  this.initRevisadosReporte();
  this.buscandoFiltro=true;
  return  this._http.post(`${this._config.URL_SERVICE_REVISADO}/getRevisadosAdmin`,Data)
                      .subscribe(
                        {
                          next:(res:ListadoRevisadosReporte)=> { 
                           this._ListadoRevisado=res;
                           for (let index = 0; index < res.totalPage; index++) {
                            this.CountPage.push(index+1)
                          }
                          },
                          error:(err)=> {
                            this.success('warning','ECARCHECK',err)
                            this.cargando=false;
                            this.buscandoFiltro=false;
                          },
                          complete:()=> {
                            this.cargando=false;
                            this.buscandoFiltro=false;
                          },
                        }
                      );
}

 GetRevisadoAdminExcel(Data:any){
  this.cargando=true;
  this.CountPage=[];
  this.buscandoFiltro=true;
  return  this._http.post(`${this._config.URL_SERVICE_REVISADO}/getRevisadosAdmin`,Data);
}

UploadFiles(Data:any){
  this.UploadFile=true;
  this.cargando=true;
  this.CountPage=[];
  this.buscandoFiltro=true;
  return  this._http.post(`${this._config.URL_SERVICE_REVISADO}/UploadFile`,Data)
                      .subscribe(
                        {
                          next:(res:ResponseDocumentos)=> { 
                            if(res.codeError == 200){
                               this.Categorizar(res);
                              this.success('success','ECARCHECK','Archivo subido correctamente.')
                            }else{
                              this.success('warning','ECARCHECK',res.message)
                            }
                            this.UploadFile=false;
                          },
                          error:(err)=> {
                            this.UploadFile=false;
                            this.success('warning','ECARCHECK','Intente Nuevamente, Falla al momento de cargar los documentos..')
                          },
                          complete:()=> {
                            this.UploadFile=false;
                          },
                        }
                      );
}

GetDocumentos(Data:any){
  this.cargando=true;
  this.CountPage=[];
  this.buscandoFiltro = true;
  console.log('====================================');
  console.log(Data);
  console.log('====================================');
  return  this._http.post(`${this._config.URL_SERVICE_REVISADO}/GetDocumentos`,Data)
                      .subscribe(
                        {
                          next:(res:ResponseDocumentos)=> {
                            this.ListadoArchivo = res;
                            this.Categorizar(this.ListadoArchivo);
                          },
                          error:(err)=> {
                          },
                          complete:()=> {
                          },
                        }
                      );
}

GetRevisadosXTaller(data:any){
  this.cargando = false;
  this._config.getUserLocal();
  this.initListadoTaller();
  if (data.IdTaller == null) {
    this.NavCtrl.navigate(['/Panel-control']);
    return;
  }

  let dataPost = {
    TallerId: data.IdTaller,
    Token: this._config.Token,
    TipoRevisado: data.TipoRevisado,
    TipoDate:data.tipo,
    CreatedBy:data.usuarioResponsable,
    DateInit:data.DateInit,
    DateEnd:data.DateEnd,
    TipoBusqueda:data.TipoBusqueda,
    Valor:data.Valor,
    Anio:data.anio
  }

  return this._http.post(`${this._config.URL_SERVICE_REVISADO}/GetRevisados`,dataPost)
                      .subscribe(
                        {
                          next:(res:RespuestaXRevisadosTalleres)=> {
                              this.ngZone.run(()=>{
                                if(res.codeError ==200){
                                  this.ListadoXTalleres=res;
                                }else{
                                  if(res.codeError == 501){
                                    let json = JSON.parse(this.data.message);
      
                                    this.success('error','Validación',this.Listado.message);
                                  }else{
                                    this.success('error','Validación',this.Listado.message);
                                  }
                                }
                              });
                          },
                          error:(err)=> {
                            this.success('warning','ECARCHECK','No se pudo cargar correctamente.');
                            this.guardandoRevisado = false;
                            this.cargando = true;
                          },
                          complete:()=> {
                            this.cargando = true;
                          },
                        }
                      );
}


GetRevisadosXTallerOne(data:any){
  this._config.getUserLocal();
  if (data.IdTaller == null) {
    this.NavCtrl.navigate(['/Panel-control']);
    return;
  }
  this.initOneRevisado();
  let dataPost = {
    Token: this._config.Token,
    UsuarioResponsable:data.UsuarioResponsable,
    IdTaller:data.IdTaller,
    TipoRevisado:data.TipoRevisado,
    TipoData:data.TipoData,
    Id_Revisado:data.Id_Revisado
  }
  console.log('====================================');
  console.log(dataPost);
  console.log('====================================');

  return this._http.post(`${this._config.URL_SERVICE_REVISADO}/GetOneRevisado`,dataPost)
                      .subscribe(
                        {
                          next: (res: OneRevisado) => {
                            console.log(res);
                            if (res.revisados == null) {
                               this.NavCtrl.navigate(['/Talleres/Gestionar/'+data.IdTaller+'/Revisados']);
                            }
                              this.ngZone.run(()=>{
                                if(!res.error){
                                  this._detalleOneRevisado = res;
                                  
                                } else {
                                   this.success('warning','ECARCHECK',res.message); 
                                }
                              });
                          },
                          error:(err)=> {
                            this.success('warning','ECARCHECK','No se pudo cargar correctamente.');
                            this.guardandoRevisado = false;
                             this.NavCtrl.navigate(['/Talleres/Gestionar/'+data.IdTaller+'/Revisados']);
                          },
                          complete:()=> {
                           
                          },
                        }
                      );
  }
  
  addImprimir(data:any){
  this._config.getUserLocal();
    return this._http.post(`${this._config.URL_SERVICE_REVISADO}/ImprimirAdd`, data);
  }
  

  AnularRevisados(data:any){
  this._config.getUserLocal();
    return this._http.post(`${this._config.URL_SERVICE_REVISADO}/AnularRevisadosAdmin`, data);
  }



Categorizar(res:ResponseDocumentos){
 
//   REGISTRO VEHICULAR
// HOJA DE ALINEAMIENTO
// POLIZA DE SEGURO
  this.DocumentosRV='';
  this.DocumentosHA='';
  this.DocumentosPS='';
  res.listado.forEach(element => {
    
    if (element.nombreArchivo == 'REGISTRO VEHICULAR') {
      this.DocumentosRV=element.file;
    }
    // if (element.nombreArchivo == 'HOJA DE ALINEAMIENTO') {
    //   this.DocumentosHA=element.file;
    // }
    if (element.nombreArchivo == 'POLIZA DE SEGURO') {
      this.DocumentosPS=element.file;
    }
  });
  //&& this.DocumentosHA!=''
  if(this.DocumentosRV!=''  && this.DocumentosPS !=''){
    this.GuardarDocumentos=true;
  }else{
    this.GuardarDocumentos=false;
  }
  
}
// fotos
    // this.data.anioRevisado=0;
    // this.data.estadoRevisado="APROBADO";
    // this.conductor.tipoPersona = 'PERSONA NATURAL';
    // this.data.tipoFormularios = 'PARTICULAR';
// URL_SERVICE_PHOTO
   init(){
     this.data.CreatedBy = this._config.info.nameid,
       this.data.TallerId = this._config.TallerActivo.id,
       this.data.TallerName = this._config.TallerActivo.razonSocial,
       this.data.Token = this._config.Token;
     this.data.anioRevisado = 0;
   }
   initInterface(){
    this.Listado = {
      listado: [],
      codeError: 0,
      message: '',
      cantidad:0,
    };
    this.data={
      anioRevisadoActual:0,
      TallerId:0,
      TallerName:'',
      nroVelocidades: '',
      tipoTransmision: '',
      nroCilindros: '',
      mesRevisado: '',
      nroLiquidacion: '',
      nombreAseguradora: '',
      cupo: '',
      restriccionVehiculos: '',
      nombrePropietario: '',
      tipoCapacidad: '',
      permisoCarga: '',
      ubicacionGeografica: '',
      fechaRevisado: '',
      tipoCombustible: '',
      tipoFrenos: '',
      tipoVehiculo: '',
      nroPlaca: '',
      nroChasis: '',
      observaciones: '',
      fechaBaja: '',
      tipoPlaca: '',
      tipoUso: '',
      nroVin: '',
      nroDocumentoPropietario: '',
      estadoVehiculo: '',
      kilometrajeVehiculo: '',
      nroPolizaSeguro: '',
      indicadorRespuesta: '',
      tipoFrenosDelanteros: '',
      revId: '',
      tipoTimon: '',
      nroPuertas: '',
      marcaVehiculo: '',
      tieneAireAcondicionado: '',
      tipoPertenencia: '',
      nroMotor: '',
      tipoFrenosTraseros: '',
      ultTallerRevisado: '',
      traccionMotor: '',
      modeloVehiculo: '',
      detalleRespuesta: '',
      hipoteca: '',
      colorVehiculo: '',
      capacidadVehiculo: '',
      ubicacionMotor: '',
      divisionGeografica: '',
      tipoDocumentoPropietario: '',
      posicionMotor: '',
      anioVehiculo: '',
      idVehiculo: '',
      cilindradaVehiculo: '',
      codeError: 0,
      message: '',
      certificadoOperacion: '',
      observacionPlaca: '',
      tipoFormularios: '',
      fechaLiquidacion: '',
      estadoRevisado: '',
      tipoRevisado: '',
      anioRevisado: undefined,
      observacionRevisadoAuto: '',
      tipoCarga: '',
      marcaMotor: '',
      CreatedBy: '',
      Token: ''
    };
    this.conductor={
      estatura: '',
      pazSalvo: '',
      alergias: '',
      tipoSangre: '',
      telefonoMovil: '',
      nroDoc: '',
      restriccionesAdministrativas: '',
      fechaVencimiento: '',
      fechaExpedicion: '',
      apellidoPaterno: '',
      segundonombre: '',
      medicacionPermanente: '',
      domicilio: '',
      detalleRespuesta: '',
      nacionalidad: '',
      identificadorRespuesta: '',
      primerNombre: '',
      restriccionesActivas: '',
      apellidoMaterno: '',
      tipodoc: '',
      sexo: '',
      telefonoResidencial: '',
      correoElectronico: '',
      montoDeuda: '',
      donaOrganos: '',
      apellidoCasada: '',
      telefonoLaboral: '',
      peso: '',
      estadoLic: '',
      paisNacimient: null,
      codeError: 0,
      message: '',
      tipoPersona: '',
      tipoDocumento: '',
      provincia: '',
      distrito: '',
      corregimiento: '',
      lugarTrabajo: '',
      identificacionRepresentante: ''
    }
    this.Cupos ={
      tipoAdministracion:         '',
    nroDocumentoPropietario:    '',
    capacidadTransporte:        '',
    indicadorRespuesta:         0,
    fechaRetiroCupo:            '',
    sindicato:                  '',
    organizacion:               '',
    vigenciaRevisado:           '',
    tieneRestriccionPlaca:      '',
    ubicacionCupo:              '',
    tipoDocumentoConcesionario: '',
    marcavehiculo:              '',
    paisProcedencia:            '',
    cupo:                       '',
    fechaAutorizacion:          '',
    tienePysVehiculo:           '',
    nombrePropietario:          '',
    estadoCupo:                 '',
    aseguradora:                '',
    nroMotor:                   '',
    tipoGravamen:               '',
    tieneAntecedenteCupo:       '',
    detalleRespuesta:           '',
    carroceriaTransporte:       '',
    modeloVehiculo:             '',
    tienePysPropietario:        '',
    rutaCupo:                   '',
    poliza:                     '',
    colorVehiculo:              '',
    nroDocumentoConcesionario:  '',
    tipoVehiculo:               '',
    nroPlaca:                   '',
    tipoDocumentoPropietario:   '',
    nombreConcesionario:        '',
    tieneMedidaCautelar:        '',
    institucionGravamen:        '',
    tipoVehiculoTransporte:     '',
    tienePysPrestatario:        '',
    anioVehiculo:               '',
    fechaCartaAutorizacion:     '',
    anioEmisionCupo:            '',
    codeError:                  0,
    message:                    '',
    }

    this.ListadoArchivo={
      codeError:0,
      message:'',
      listado:[]
    }
    this.RespuestPre={
      codeError:0,
      message:'',
      aleatorio:'',
      idRevisados:'',
      tipoRevisado:'',
    }
    this.anios=[]
  }

  initRevisadosReporte(){
    // this.cargando=false;
    this._ListadoRevisado={
      data:[
        {
          revisadoId:0,
          codigoTaller:'...........',
          nombreTaller:'...........',
          placa:'...........',
          anioRevisado:'...........',
          tipoRevisado:'...........',
          estado:'...........',
          responsable:'...........',
          fechaRevisado:'2000-01-01',
          revId:'...........',
          tipoPlaca:'...........',
          TallerId:''
        }
      ],
      totalPage:0,
      totalRecord:0,
      codeError:0,
      message:'',
      idRevisados:'',
      tipoRevisado:'',
      aleatorio:'',
    }
    this.CountPage=[];
  }
   success(type:string,title:string,text:string) {
    Swal.fire(
      title,
      text,
      type
    );
  }

  initListadoTaller() {
    this.ListadoXTalleres = {
      aleatorio: '',
      cantidad: 0,
      codeError: 0,
      idRevisados: '',
      message: '',
      listado: [
        {
          revisadoId:0,
          codigoTaller:'',
          nombreTaller:'',
          placa:'',
          anioRevisado:'',
          tipoRevisado:'',
          tipoPlaca:'',
          estado:'',
          responsable:'',
          fechaRevisado:'',
          revId: '',
          imprimir:0
        },
      ],
      tipoRevisado:''
    }
  }

  initOneRevisado() {
    this._detalleOneRevisado = {
      codeError: 0,
      error: false,
      imagen: [
        {
          imagenRevisadoId:0,
          createdBy:'',
          createdDate:new Date(),
          tipo:'',
          placa:'',
          image:'',
          anno:0,
        }
      ],
      message: '',
      propietarios: [],
      revisados: {
        revisadoId:0,
        anioRevisado:0,
        tallerId:0,
        tallerName:'',
        nroVelocidades:'',
        tipoTransmision:'',
        nroCilindros:'',
        mesRevisado:'',
        nroLiquidacion:'',
        nombreAseguradora:'',
        cupo:'',
        restriccionVehiculos:'',
        nombrePropietario:'',
        tipoCapacidad:'',
        permisoCarga:'',
        ubicacionGeografica:'',
        fechaRevisado:new Date(),
        tipoCombustible:'',
        tipoFrenos:'',
        tipoVehiculo:'',
        nroPlaca:'',
        nroChasis:'',
        observaciones:'',
        fechaBaja:'',
        tipoPlaca:'',
        tipoUso:'',
        nroVin:'',
        nroDocumentoPropietario:'',
        estadoVehiculo:'',
        kilometrajeVehiculo:'',
        nroPolizaSeguro:'',
        indicadorRespuesta:'',
        tipoFrenosDelanteros:'',
        revId:'',
        tipoTimon:'',
        nroPuertas:'',
        marcaVehiculo:'',
        tieneAireAcondicionado:'',
        tipoPertenencia:'',
        nroMotor:'',
        tipoFrenosTraseros:'',
        ultTallerRevisado:'',
        traccionMotor:'',
        modeloVehiculo:'',
        hipoteca:'',
        colorVehiculo:'',
        capacidadVehiculo:'',
        ubicacionMotor:'',
        divisionGeografica:'',
        tipoDocumentoPropietario:'',
        posicionMotor:'',
        anioVehiculo:'',
        idVehiculo:'',
        cilindradaVehiculo:'',
        certificadoOperacion:'',
        observacionPlaca:'',
        tipoFormularios:'',
        fechaLiquidacion:'',
        estadoRevisado:'',
        tipoRevisado:'',
        observacionRevisadoAuto:'',
        tipoCarga:'',
        marcaMotor:'',
        propietarioId:0,
        imprimir:0,
        valido:0,
        propietarios:'',
        createdAt:new Date(),
        updatedAt:new Date(),
        createdby:'',
      },
      upload: [
        {
          uploadId:0,
          placa:'',
          anio:'',
          latitud:'',
          longitud:'',
          tallerId:'',
          tallerName:'',
          nombreArchivo:'',
          file:'',
          createdAt:new Date(),
          updatedAt:new Date(),
          createdby:'',
        }
      ]
  }
}
  
}
