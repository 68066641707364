<div class="dark-body-only" id="outer-container">
  <!-- page-wrapper Start-->
  <div class="page-wrapper" [ngClass]="customizer.data.settings.sidebar.type" id="canvas-bookmark">
    <app-header (rightSidebarEvent)="rightSidebar($event)"></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper" [ngClass]="customizer.data.settings.sidebar.body_type">
      <div class="page-sidebar" id="pages-sidebar" [class.open]="navServices.collapseSidebar"
        [ngClass]="customizer.data.settings.sidebar_backround"
        [attr.sidebar-layout]="this.customizer.data.settings.sidebar_setting">
        <app-sidebar></app-sidebar>
      </div>
      <div class="right-sidebar" [class.show]="right_side_bar" id="right_side_bar">
        <app-right-sidebar></app-right-sidebar>
      </div>
      <div class="page-body">
        <app-breadcrumb></app-breadcrumb>
        <main [@animateRoute]="getRouterOutletState(o)">

         <div class="col-12" *ngIf="cantidad>0">
            <div *ngFor="let item of dataFirestore">
              <div *ngIf="item.payload.doc.data()['all'] == 1" class="style_alerta"
                [ngStyle]="{'background': item.payload.doc.data()['style'] }">
                <p style="font-size: 15px;font-weight: 500;text-align: justify;"
                  [ngStyle]="{'color':item.payload.doc.data()['color']}"> <i [ngStyle]="{'color':item.payload.doc.data()['color']}"
                    style="font-size: 20px;" class="{{ item.payload.doc.data()['icon'] }}"></i> {{
                  item.payload.doc.data()['message'] }}</p>
              </div>
              <div *ngIf="item.payload.doc.data()['all'] == 0">
                <div *ngFor="let itemTaller of item.payload.doc.data()['taller']">
                  <div *ngIf="itemTaller.TallerI == Service.TallerActivo.id" class="style_alerta"
                    [ngStyle]="{'background': item.payload.doc.data()['style'] }">
                    <p style="font-size: 13px;font-weight: 500;text-align: justify;"
                      [ngStyle]="{'color':item.payload.doc.data()['color']}"><i
                        [ngStyle]="{'color':item.payload.doc.data()['color']}" style="font-size: 20px;"
                        class="{{ item.payload.doc.data()['icon'] }}"></i> {{ item.payload.doc.data()['message'] }}</p>
                  </div>
                </div>
              </div>
            </div>
         </div> 
          <!-- <ngb-accordion activeIds="preventchange" *ngIf="cantidad>0" >
          <ngb-panel  id="preventchange-1" title="AVISOS IMPORTANTES ({{ cantidad }})">
            <ng-template ngbPanelContent>
              <div *ngFor="let item of dataFirestore">
                <div *ngIf="item.payload.doc.data()['all'] == 1" class="style_alerta" [ngStyle]="{'background': item.payload.doc.data()['style'] }">
                  <p style="font-size: 13px;font-weight: 500;text-align: justify;"  [ngStyle]="{'color':item.payload.doc.data()['color']}"> <i [ngStyle]="{'color':item.payload.doc.data()['color']}" style="font-size: 20px;" class="{{ item.payload.doc.data()['icon'] }}"></i> {{ item.payload.doc.data()['message'] }}</p>
                </div>
                <div *ngIf="item.payload.doc.data()['all'] == 0">
                  <div *ngFor="let itemTaller of item.payload.doc.data()['taller']">
                    <div *ngIf="itemTaller.TallerI == Service.TallerActivo.id"  class="style_alerta" [ngStyle]="{'background': item.payload.doc.data()['style'] }" >
                    <p style="font-size: 13px;font-weight: 500;text-align: justify;"  [ngStyle]="{'color':item.payload.doc.data()['color']}"><i [ngStyle]="{'color':item.payload.doc.data()['color']}" style="font-size: 20px;"
                      class="{{ item.payload.doc.data()['icon'] }}"></i> {{ item.payload.doc.data()['message'] }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
           
          </ngb-panel>
          </ngb-accordion> -->
         <p *ngIf="cantidad>0">
          <br>
         </p>
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
      <!-- footer start-->
      <footer class="footer">
        <app-footer></app-footer>
      </footer>
      <!-- footer End-->
    </div>
    <!-- Page Body End-->
  </div>
  <!-- page-wrapper End-->
</div>
<!-- <app-customizer></app-customizer> -->